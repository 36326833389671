import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class ElectricsPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Complete Rewiring",
      "Part Rewiring",
      "Lighting",
      "New Build Installations",
      "Certificates"
    ];

    const pricingItems = [
        {"service":"", "price1":"Monday-Friday 8am-6pm", "price2":"Evenings & Weekends"},
        {"service":"General Electric work", "price1":"£79 / hour", "price2":"£120 / hour"},
        {"service":"EPC", "price1":"£79", "price2":"£120"},
      ]

    const listServiceItems = serviceItems.map((item, index)=>
      <li className="services" key={index}>
        <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
        {item}
      </li>
    )

    const listPricingItems = pricingItems.map((item, index)=>
      <Row key={index}>
        <Col lg={3} xs={4} className="pricing">{item.service}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price1}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price2}</Col>
      </Row>
    )

    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Electrics</title>
          <meta name="description" content="Electrics - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Electrics" 
          img="/home/banner-electrics.jpg"
          color="var(--primary-blue)"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Electrics</h2>
            <p className="servicesExplainer bold">Fully insured Qualified Electricians that maintain high standards of safety while providing great quality of workmanship at reasonable rates. You will never be overcharged and we will provide a guarantee for the work carried out.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Pricing</h2>
              {listPricingItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Complete Rewiring & Installations</h2>
              <p className="servicesExplainer light">Competitively priced, call us for a quote.</p>
            </Col>
          </Row>

          <CallToAction service />

        </Container>
      </div>
    );
  }
}

export default ElectricsPage;