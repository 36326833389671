import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import styled from 'styled-components';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const HeaderWrap = styled.div`
  background:url(${props => props.img});
  background-position:${props => props.position};
`;

class PageHeader extends Component {

  render() {
    return (
      <HeaderWrap 
        className="pageHeader" 
        id={this.props.imageId} 
        img={this.props.img}
        position={this.props.position}
      >
      {/* <div className="pageHeader" id={this.props.imageId}> */}
          <Container>
              <Row>
                <Col>
                  <h1 style={{color:this.props.color}}>{this.props.name}</h1>
                </Col>
              </Row>
          </Container>
      {/* </div> */}
      </HeaderWrap>
    )
  }
}

export default withRouter (PageHeader)