import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class PaintingPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Complete Painting & Decorating",
      "Touch Ups/ Fresh Coat",
      "Wood Work Painting",
      "Interior & Exterior",
      "New Builds"
    ];

    const pricingItems = [
      {"service":"Monday-Friday 8am-6pm", "price":"£29/hour Or £199/day"},
      {"service":"Evenings & Weekends", "price":"£49/hour Or £299/day"},
    ]

    const listServiceItems = serviceItems.map((item, index)=>
      <li className="services" key={index}>
        <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
        {item}
      </li>
    )

    const listPricingItems = pricingItems.map((item, index)=>
      <Row key={index}>
        <Col lg={4} xs={9} className="pricing">{item.service}</Col>
        <Col lg={2} xs={3} className="pricing tr">{item.price}</Col>
      </Row>
    )

    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Painting</title>
          <meta name="description" content="Painting - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Painting" 
          img="/home/banner-painting.jpg"
          position="center"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Painting</h2>
            <p className="servicesExplainer bold">Professional Painter and Decorators that have an eye for colour. They will bring with them years of experience and advice along with quality service. You will never be overcharged and we will provide a guarantee for the work carried out.</p>
            <p className="servicesExplainer light">Our Painter & Decorators have years of experience in the field. They know what looks good and will be able to advice you or if you wish simply follow your instructions.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Painting & Decorating Rates</h2>
              {listPricingItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Large Projects/ Painting New Builds</h2>
              <p className="servicesExplainer light">Competitively priced, call us for a quote.</p>
            </Col>
          </Row>

          <CallToAction service />

        </Container>
      </div>
    );
  }
}

export default PaintingPage;

// Comp1
// Picture
// Main Text

// Comp2
// Fees

// Comp3
// Services Offered

// Comp 4
// Services Offered title+text

//Comp 5
// Contact Footer