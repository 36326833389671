import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'

class GuaranteePage extends Component {
  render() {
    return (
      <div className="guaranteePage" style={{marginBottom:"25rem"}}>

      <Helmet>
        <title>Property People Care - Guarantee</title>
        <meta name="description" content="Property People Care" />
      </Helmet>

      <PageHeader 
        name="Guarantee" 
        img="/home/banner-sustainable.jpg"
        position="center"  
      />

        <Container>
          <Row>
            <Col>
            <h2 className="sectionHead">Property People Guarantee</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <img src="/guarantee/guarantee.jpg" alt="Property People Guarantee"/>
            </Col>
            <Col lg={9}>
            <p className="servicesExplainer bold">Property People will provide a guarantee for all work carried out.</p>
            <p className="servicesExplainer">The terms of the guarantee will be listed on your invoice.</p>
            <p className="servicesExplainer">The guarantee will require that you act on the recommendations of our workmen so the problem does not return or persist further.</p>
            <p className="servicesExplainer">Property People will make you aware of the problem in a clear understandable way.</p>
            <p className="servicesExplainer">Property People will always make you aware of the charges before starting the work. </p>
            <p className="servicesExplainer">Property People will never carry out unnecessary work.</p>
            <p className="servicesExplainer">You will never be overcharged. If any work is carried out. First hour is billable in full then half hour increments will be charged.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GuaranteePage;