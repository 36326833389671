import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class ExtensionsPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Loft Conversions",
      "Extensions",
      "Conservatories",
      "Kitchen refurbishment",
      "Bathroom Installations"
    ];

    const listServiceItems = serviceItems.map((item)=>
    <li className="services" key={item}>
      <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
      {item}
    </li>
  )

    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Extensions</title>
          <meta name="description" content="Extensions - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Extensions" 
          img="/home/banner-extensions.jpg"
          position="center"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Extensions</h2>
            <p className="servicesExplainer bold">Property People can see your dream extension/conversion project through to completion with a beautiful finish. We can take on all size of refurbishment projects. Call us to discuss your needs. We would be more than happy to provide a quote.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
        </Row>

        <CallToAction service={true} />

        </Container>
      </div>
    );
  }
}

export default ExtensionsPage;