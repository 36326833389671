import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const Wrapper = styled.div`
  background: var(--primary-blue);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 0.15rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ItemWrap = styled.a`
  text-align: center;
  color: var(--light-blue);
`;

const Txt = styled.p`
  font-size: 0.7rem;
  margin-bottom: 3px;
`;

const Logo = styled.img`
  height: 1.5rem;
`;

const NotCurrent = {
  textAlign: "center",
};

const Current = {
  textAlign: "center",
  borderBottom: "2px solid var(--light-blue)",
};

const Sites = [
  {
    name: "swcarpetcleaning.co.uk",
    link: "https://swcarpetcleaning.co.uk",
    img: "./common/sw-carpet-cleaning-logo.png",
  },
  {
    name: "property-people.net",
    link: "https://property-people.net",
    img: "./common/property-people-logo.svg",
    current: "yes",
  },
  {
    name: "property-people.co.uk",
    link: "https://property-people.co.uk",
    img: "./common/pp-logo.png",
    height: ".9rem",
  },
];

class TopStoreNav extends Component {
  render() {
    const ListSites = Sites.map((item, index) => (
      <Col lg={4} style={item.current ? Current : NotCurrent} key={index}>
        <ItemWrap href={item.link}>
          <Logo
            src={item.img}
            style={item.height ? { height: item.height } : { height: "1.5rem" }}
          />
          <Txt>{item.name}</Txt>
        </ItemWrap>
      </Col>
    ));

    return (
      <Container style={{ padding: "0" }}>
        <Wrapper>
          <Row style={{ marginLeft: "0", marginRight: "0" }}>{ListSites}</Row>
        </Wrapper>
      </Container>
    );
  }
}

export default TopStoreNav;
