import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

class RefurbishmentPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Sustainable Refurbishment</title>
          <meta name="description" content="Sustainable Refurbishment - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Sustainable Refurbishment" 
          img="/home/banner-sustainable.jpg"
          position="center"
          color="var(--primary-blue)"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Refurbishment</h2>
            <p className="servicesExplainer bold">Low quality properties can create many problems for occupants including high utility bills and a wide variety of negative health impacts resulting from cold, damp and poorly ventilated homes. By refurbishing existing properties to a good standard of sustainable design, running costs will be lower, the internal environment healthier and as a result it will be a more desirable place to live/work for the occupants, potentially increasing value.</p>
            <p className="servicesExplainer light">When a property is being renovated, whether to bring it up to a modern liveable standard or to convert a large property into flats, it is a good time to consider what wider opportunities there are for improving the home and making it more economically, socially and environmentally sustainable.</p>
            <p className="servicesExplainer light">We always suggest and recommend our customers to improve the water efficiency, minimising health impacts such as providing adequate ventilation and daylighting.</p>
            <p className="servicesExplainer light">Whenever we undertake a refurbishment project we tried our best to significantly enhance the value of the property and improve the internal environment for the occupants. We also ensure that the project runs effectively and with a low impact on neighbours and the wider environment.</p>
            </Col>
          </Row>

          <CallToAction service />

        </Container>
      </div>
    );
  }
}

export default RefurbishmentPage;

// Comp1
// Picture
// Main Text

// Comp2
// Fees

// Comp3
// Services Offered

// Comp 4
// Services Offered title+text

//Comp 5
// Contact Footer