import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import axios from 'axios'
import './Quote.css'
import Helmet from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import ModalPopup from '../../components/ModalPopup'

// Base prices
const onebed = '300';
const twobed = '600';
const threebed = '900';
const fourbed = '1200';
const fivebed = '1500';
const sixbed = '1800';
const sevenbed = '2100';
const eightbed = '2400';

class QuotePaintingPage extends Component {

  constructor(props) {
    super(props)
    this.state = {

      // Quote values
      propertySize:onebed, 
      stairs:'0',
      lounge:'0',
      dining:'0',
      kitchen:'0',
      bathrooms:'0',
      windows:'0',
      doors:'0',
      furnished:'1', // 100% i.e. no change in price
      carpeted:'1', // 100% i.e. no change in price

      // Info boxes
      info: "",
      infoLeft: "",
      infoTop: "",
      infoOpacity: "0",

      // Email related states
      name:"Not provided",
      email:"",
      phone: "Not provided",
      address: "Not provided",
      discount: 1,
      quoteTotal:0,
      thanksOpacity: 0,
      emailBtnOpacity: 1,
      emailBtnPointerEvents: "all",
      emailValidation:"",
      contactChecked:true,

      // Modal/discount states
      modalOpacity:0,
      modalPointerEvents: "none",
      noDiscountQuoteOpacity:0,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onCheckboxSelect = this.onCheckboxSelect.bind(this);
  }

  onNameChange(e){
    this.setState({name: e.target.value})
  }

  onEmailChange(e){
    this.setState({email: e.target.value})
  }

  onPhoneChange(e){
    if(isNaN(e.target.value)){
      console.log("Please use numbers only") // Handle phone number validation
    } else {
      this.setState({phone: e.target.value})
    }
  }

  onAddressChange(e){
    this.setState({address: e.target.value})
  }

  onCheckboxSelect(){
    this.state.contactChecked ? this.setState({contactChecked:false}) : this.setState({contactChecked:true})
    console.log(this.state.contactChecked)
  }

  quoteCalc(){

  }

  handleSubmit(e){
    e.preventDefault();

    if(!(this.state.email).includes("@" && ".")){ // Handle email validation
      this.setState({emailValidation:"Please enter a valid email address"})
    } else {

    this.setState({
      emailBtnOpacity: 0,
      thanksOpacity: 1,
      emailBtnPointerEvents:"none",
      emailValidation:"",
    });

    let quoteTotal = e.target.name

    let txt = {
      propertysize:"Property size: " + document.getElementById('propertySize').selectedOptions[0].text,
      stairs:"Sets of stairs & hallway: " + document.getElementById('stairs').selectedOptions[0].text,
      lounge:"Lounge: " + document.getElementById('lounge').selectedOptions[0].text,
      dining:"Dining room: " + document.getElementById('dining').selectedOptions[0].text,
      kitchen:"Kitchen: " + document.getElementById('kitchen').selectedOptions[0].text,
      bathrooms:"Bathrooms: " + document.getElementById('bathrooms').selectedOptions[0].text,
      windows:"Window Frames: " + document.getElementById('windows').selectedOptions[0].text,
      doors:"Doors and door frames: " + document.getElementById('doors').selectedOptions[0].text,
      furnished:"Is the property furnished? - " + document.getElementById('furnished').selectedOptions[0].text,
      carpeted:"Is the property carpeted? - " + document.getElementById('carpeted').selectedOptions[0].text,
    }

    let emailToMe = {
      "fromEmail" : "info@property-people.net",
      "toEmail" : "info@property-people.net",
      "subject": `New quote request from ${this.state.name}`,
      "body":`<div style="font-size:18px; line-height:1.5;">
              Name: <b>${this.state.name} </b><br/>
              Email: <b>${this.state.email} </b><br/>
              Phone: <b>${this.state.phone} </b><br/>
              Address: <b>${this.state.address} </b><br/>
              Quote amount: <b>£${quoteTotal} </b> (${txt.propertysize}, ${txt.stairs}, ${txt.lounge}, ${txt.dining}, ${txt.kitchen}, ${txt.bathrooms}, ${txt.windows}, ${txt.doors}, ${txt.furnished}, ${txt.carpeted})<br/>
              Contact now to book an appointment? - <b>${this.state.contactChecked ? "Yes" : "No"} </b><br/>
              </div>
              <div style="font-size:14px; padding-top:12px">
                <i>This form was sent using property-people.net quote form.</i>
              </div>`
    }

    let emailToUser = {
      "fromEmail" : "info@property-people.net",
      "toEmail" : `${this.state.email}`,
      "subject": `Thank you for interest in our services`,
      "body":`<div style="font-size:16px; line-height:1.5;">

      <p>Hello ${this.state.name},</p>
      <p>Thank you for interest in our services. Here is your quote: <b>£${quoteTotal}</b>
      (${txt.propertysize}, ${txt.stairs}, ${txt.lounge}, ${txt.dining}, ${txt.kitchen}, ${txt.bathrooms}, ${txt.windows}, ${txt.doors}, ${txt.furnished}, ${txt.carpeted})</p>
      <p>This quote is tailored in such a way that allows you to get value for money by doing some of the work yourself like moving furniture or vacuuming beforehand. Or you can leave it all to us. Either way, you will be surprised by the results.</p>
      <p> What happens next?</p>
      <ul>
        <li>If you have checked “Contact me now to book an appointment” we will call you shortly to do exactly that.
        <li>If you were simply curious about our prices, that is fine too. We may follow up with an email to check if you were still interested and if there is anything, we can do to win your business.</li>
      </ul>
      <p>Here at Property People we hire the most skilful builders and tradesman. Sometimes we will hire subcontractors but they will all be backed up by our own comprehensive liability insurance, staff in our office and the best tools & machinery for the jobs we do.</p>
      <p>Our staff are highly trained professionals with health and safety and machines and tools training.</p>
      <p>We look forward to seeing you soon and leaving you with a lovely finish for your refurb project.</p>
      <br>
      <p>Kind Regards</p>
      <p>Property People Care Team.</p>
      <p>P.S. Ask us about our other services. We also do extensions, flooring, decking, kitchens and bathroom.</p>

      </div>
      <div style="font-size:14px; padding-top:12px">
        <i>This form was sent using property-people.net quote form.</i>
      </div>`
    }
  
    axios.post(`https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`, emailToMe)
      .then(res => {
        // console.log(res);
        // console.log(res.data)
        // console.log(emailToMe)
        console.log("Email to me set");
    })

    axios.post(`https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`, emailToUser)
      .then(res => {
        // console.log(res);
        // console.log(res.data)
        // console.log(emailToUser)
        console.log("Email to user sent");
    })

    }
  };

  handleModal(){
    this.setState({
      discount:0.9,
      modalOpacity:0,
      modalPointerEvents:"none",
      noDiscountQuoteOpacity: 1
    })
  };

  closeModal(){
    this.setState({
      modalOpacity:0,
      modalPointerEvents:"none",
    })
  };

  handleChange(e) {

    this.setState({
      [e.target.name]: e.target.value
    });

  }

  handleHover(e){
    this.setState({
      infoTop: e.target.offsetTop + 280,
      infoLeft: e.target.getBoundingClientRect().x + 25,
      infoOpacity: 1,
    })

    switch(e.target.getAttribute("name")){
      case "propertySize" :       this.setState({info: "For the purposes of an instant a quote we will assume rooms sizes are standard as they generally are very similar in terraced and semi detaced houses. Let us know how many bedrooms there are."}); break;
      case "stairs" :          this.setState({info: "If this is a property on multiple floors than let us know how many sets of stairs and hallways you would like painted."}); break;
      case "lounge" :      this.setState({info: "Let us know if you have a lounge that you would like to have painted. Some time lounges are double sized, often to accommodate a dining area or kitchen. If you lounge is open plan with kicthen, select that option."}); break;
      case "dining" :    this.setState({info: "Let us know if you have a separate dining room that you would like to have painted. Some time dining rooms are double sized. If you Dning room is open plan with kicthen, select that option"}); break;
      case "kitchen" :       this.setState({info: "Let us know if you have a Separate kitchen that you would like to have painted. Some time dining rooms are double sized."}); break;
      case "bathrooms" :       this.setState({info: "How many bathrooms need to be painted."}); break;
      case "windows" :           this.setState({info: "How window frames need to be painted."}); break;
      case "doors" :               this.setState({info: "How doors and door frames need to be painted. If this is a rental property with HMO license then doors are likely FD30 fire rated. If this is the case, let us know and we will apply fire rated paint to the doors to comply with HMO regulations."}); break;
      case "furnished" :               this.setState({info: "If the property is funished, than we may have to move furniture to the center of the room and cover to avoid getting paint splatter and marks on it."}); break;
      case "carpeted" :               this.setState({info: "If the property is carpeted, than we will have to cover it to avoid getting paint splatter and marks on it."}); break;
      default: console.log("defs");
    }
  };

  handleMouseOut(){
    this.setState({
      infoOpacity: 0,
    })
  }

async componentDidMount() {
  window.scroll(0, 0);
  setTimeout(()=>{
    this.setState({
      modalOpacity: 1,
      modalPointerEvents:"all"
    })
  }, 120000)

  document.getElementById('quote').classList.add("activeMenu")
  
}


  render() {
    let subtotal = parseFloat(this.state.propertySize) + parseFloat(this.state.stairs) + parseFloat(this.state.lounge) + parseFloat(this.state.dining) + parseFloat(this.state.kitchen);

    let noDiscountQuote = 
    (parseFloat(this.state.propertySize) + 
    parseFloat(this.state.stairs) +
    parseFloat(this.state.lounge) + 
    parseFloat(this.state.dining) + 
    parseFloat(this.state.kitchen) +     
    parseFloat(this.state.bathrooms) + 
    parseFloat(this.state.windows) +
    parseFloat(this.state.doors) +
    (parseFloat(this.state.furnished) * subtotal) - subtotal + // +10% 
    (parseFloat(this.state.carpeted) * subtotal) - subtotal // +10% 
    );

    let quote = noDiscountQuote * this.state.discount;

    return (
      <div className="quotePageWrapper">

      <Helmet>
        <title>Property People - Painting Quote</title>
        <meta name="description" content="SW Cartpet Cleaning - End of tenancy quote" />
      </Helmet>

          <PageHeader 
            img="/home/banner-painting.jpg"
            name="Painting quote"
            color="white"
          />
          <Container>
              <Row>
                <Col>
                  <h2 className="sectionHead">Tailor your painting quote</h2>

                  <label name="propertySize">Property size 
                    <div className="questionMarkW" name="propertySize" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="propertySize" id="propertySize" onChange={this.handleChange}>
                    {/* <option value={studio}>Studio £{studio}</option> */}
                    <option value={onebed}>1 Bed £{onebed}</option>
                    <option value={twobed}>2 Bed £{twobed}</option>
                    <option value={threebed}>3 Bed £{threebed}</option>
                    <option value={fourbed}>4 Bed £{fourbed}</option>
                    <option value={fivebed}>5 Bed £{fivebed}</option>
                    <option value={sixbed}>6 Bed £{sixbed}</option>
                    <option value={sevenbed}>7 Bed £{sevenbed}</option>
                    <option value={eightbed}>8 Bed £{eightbed}</option>
                  </select>

                  <label name="stairs">Sets of stairs & hallway? 
                    <div className="questionMarkW" name="stairs" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="stairs" id="stairs" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="300">1</option>
                    <option value="600">2</option>
                    <option value="900">3</option>
                    <option value="1200">4</option>
                  </select>

                  <label name="lounge">Lounge 
                    <div className="questionMarkW" name="lounge" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="lounge" id="lounge" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="300">Single</option>
                    <option value="500">Double</option>
                  </select>

                  <label name="dining">Dining Room 
                    <div className="questionMarkW" name="dining" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="dining" id="dining" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="300">Single</option>
                    <option value="500">Double</option>
                  </select>

                  <label name="kitchen">Kitchen 
                    <div className="questionMarkW" name="kitchen" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="kitchen" id="kitchen" onChange={this.handleChange}>
                    <option value="0">No</option>
                    <option value="200">Yes</option>
                  </select>

                  <label name="bathrooms">Bathrooms 
                    <div className="questionMarkW" name="bathrooms" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="bathrooms" id="bathrooms" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="175">1</option>
                    <option value="350">2</option>
                    <option value="525">3</option>
                    <option value="700">4</option>
                    <option value="875">5</option>
                    <option value="1050">6</option>
                    <option value="1225">7</option>
                    <option value="1440">8</option>
                  </select>

                  <label name="windows">Window Frames
                    <div className="questionMarkW" name="windows" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="windows" id="windows" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="60">1</option>
                    <option value="120">2</option>
                    <option value="180">3</option>
                    <option value="240">4</option>
                    <option value="300">5</option>
                    <option value="360">6</option>
                    <option value="420">7</option>
                    <option value="480">8</option>
                  </select>

                  <label name="doors">Doors and Door Frames
                    <div className="questionMarkW" name="doors" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="doors" id="doors" onChange={this.handleChange}>
                    <option value="0">N/A</option>
                    <option value="200">1</option>
                    <option value="400">2</option>
                    <option value="600">3</option>
                    <option value="800">4</option>
                    <option value="1000">5</option>
                    <option value="1200">6</option>
                    <option value="1400">7</option>
                    <option value="1600">8</option>
                    <option value="1800">9</option>
                    <option value="2000">10</option>
                    <option value="2200">11</option>
                    <option value="2400">12</option>
                    <option value="2600">13</option>
                    <option value="2800">14</option>
                    <option value="3000">15</option>
                  </select>

                  <label name="furnished">Is the property furnished? 
                    <div className="questionMarkW" name="furnished" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="furnished" id="furnished" onChange={this.handleChange}>
                    <option value="1">No</option>
                    <option value="1.1">Yes</option>
                  </select>

                  <label name="carpeted">Is the property carpeted? 
                    <div className="questionMarkW" name="carpeted" onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
                      <FontAwesomeIcon 
                        icon={faQuestionCircle} 
                        className="questionMark"
                      />
                    </div>
                  </label>
                  <select name="carpeted" id="carpeted" onChange={this.handleChange}>
                    <option value="1">No</option>
                    <option value="1.1">Yes</option>
                  </select>

                  <h2 className="sectionHeadAlt">Your quote is:</h2>
                  <div className="quoteTotal">
                    £{quote.toFixed(2)}
                      <span className="oldQuote" style={{opacity:this.state.noDiscountQuoteOpacity}}>
                        £{noDiscountQuote.toFixed(2)}
                      </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="sectionHead">Your Details</h2>
                </Col>
              </Row>

              {/* Submit form */}
              <form 
                id="quoteForm" 
                className="quoteForm" 
                onSubmit={this.handleSubmit}
                name={quote.toFixed(2)} // Passing the final quote to Email via name attribute :p
                noValidate
              >
              <Row>
                <Col sm={12} md={4}>
                  <label htmlFor="Name">Name*</label>
                  <input type="text" onChange={this.onNameChange} required></input>
                </Col>
                <Col sm={12} md={4}>
                  <label htmlFor="Email">Email*</label>
                  <input type="text" onChange={this.onEmailChange} required></input>
                  <div id="emailValidation" className="validationBox">{this.state.emailValidation}</div>
                </Col>
                <Col sm={12} md={4}>
                  <label htmlFor="Phone">Phone Number</label>
                  <input type="text" onChange={this.onPhoneChange}></input>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="Address">Address</label>
                  <input type="text" onChange={this.onAddressChange}></input>
                </Col>
              </Row>
              <Row>
                <Col>
                  <input type="checkbox" onChange={this.onCheckboxSelect} checked={this.state.contactChecked}></input>
                  <label htmlFor="ContactMe" className="contactMe">Contact me now to book an appointment</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button 
                    type="submit" 
                    className="btn btn-primary" 
                    style={{opacity:this.state.emailBtnOpacity, pointerEvents:this.state.emailBtnPointerEvents}}
                    >
                    Email Me The Quote
                  </button>
                  <div className="submitThanks" style={{opacity:this.state.thanksOpacity}}>
                    <strong>Thank you for your enquiry! Email is now on its way.</strong><br/>
                    If you have requested an appointment, we will get back to you as soon as possible.
                  </div>
                </Col>
              </Row>
              </form>

          </Container>

        <div className="infoBoxQuote" 
        style={{top:this.state.infoTop + "px", left:this.state.infoLeft + "px", opacity:this.state.infoOpacity}}
        >
          {this.state.info}
        </div>

        <ModalPopup 
          onClick={this.handleModal}
          closeModal={this.closeModal}
          opacity={this.state.modalOpacity}
          pointerEvents={this.state.modalPointerEvents}
        />

      </div>
    )
  }
}

export default withRouter (QuotePaintingPage)