import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as GoogleLogoSVG } from "../images/googlereviews/google-logo.svg";
import { ReactComponent as GoogleFiveStars } from "../images/googlereviews/google-5-stars.svg";
import { ReactComponent as GoogleFourStars } from "../images/googlereviews/google-4-stars.svg";

const ReviewWrap = styled.div`
  padding: 1rem;
`;
const Review = styled.div`
  font-size: 0.825rem;
  color: var(--primary-blue);
`;

const Author = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  padding-top: 0.5rem;
`;

const SummaryColumn = styled.div`
  text-align: center;
`;

const ReviewSummary = styled.div`
  font-size: 1.5rem;
  color: var(--primary-blue);
  font-weight: 500;
`;

const FiveStars = styled(GoogleFiveStars)`
  width: 8rem;
  padding-right: 2rem;
`;

const FourStars = styled(GoogleFourStars)`
  width: 6.7rem;
  padding-right: 2rem;
`;

const ReviewCount = styled.div`
  font-size: 0.825rem;
  color: var(--primary-blue);
  padding-bottom: 0.5rem;
`;

const GoogleLogo = styled(GoogleLogoSVG)`
  width: 40%;
  height: auto;
`;

const StarsDateWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  padding-bottom: 0.5rem;
`;

const Date = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  padding-top: 0.5rem;
  text-align: right;
`;

const TempData = {
  companyName: "Property People Care",
  reviewsCount: "2 reviews",
  avgScore: "5.0",
  reviews: [
    {
      body: "Great attention to detail and very responsive as always.",
      author: "David Wright",
      date: "a day ago",
      rating: " 5 stars ",
    },
    {
      body: "Property People Care has been a wonderful company to deal with!",
      author: "Francis Kolms",
      date: "today",
      rating: " 5 stars ",
    },
  ],
};

class GoogleWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyData: {},
      isLoaded: false,
    };
  }

  componentDidMount() {
    let query = {
      query: "{ companies {companyName reviewsCount avgScore reviews{body author date rating}} }",
    };
    axios
      .post("https://api.property-people.net/graphql", query)
      .then((res) => {
        for (let i = 0; i < res.data.data.companies.length; i++) {
          res.data.data.companies[i].companyName === "Property People Care"
            ? this.setState({ companyData: res.data.data.companies[i] })
            : console.log(false);
        }
      })
      .then(() => {
        this.setState({ isLoaded: true });
        console.log(this.state.companyData);
      });
  }

  render() {
    const reviewSliderSettings = {
      infinite: true,
      speed: 2000,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="googleWrapper" style={{ margin: "5rem 0 3rem 0" }}>
        <Row>
          <Col lg={3}>
            <SummaryColumn>
              <a
                href="https://www.google.co.uk/maps/place/Property+People+Care+Ltd/@51.4235854,-0.1751468,17z/data=!4m5!3m4!1s0x48760766e7b5dd7d:0xb5a20159fc2ba3ca!8m2!3d51.4235821!4d-0.1729581"
                target="_blank"
              >
                <ReviewSummary>Excellent</ReviewSummary>
                <FiveStars style={{ width: "80%", padding: "0.5rem" }} />
                <ReviewCount>
                  {TempData.avgScore} based on {TempData.reviewsCount}
                </ReviewCount>
                <GoogleLogo />
              </a>
            </SummaryColumn>
          </Col>

          <Col lg={9}>
            <Slider {...reviewSliderSettings}>
              {this.state.isLoaded
                ? TempData.reviews.map((review, index) => (
                    <ReviewWrap key={index}>
                      <StarsDateWrap>
                        {review.rating.includes("5") ? <FiveStars /> : <FourStars />}
                        <Date>{review.date}</Date>
                      </StarsDateWrap>
                      <Review>
                        {review.body.slice(0, 100)}
                        {review.body.length > 110 ? "..." : ""}
                      </Review>
                      <Author>{review.author}</Author>
                    </ReviewWrap>
                  ))
                : null}
            </Slider>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(GoogleWidget);
