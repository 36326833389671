import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'

class Footer extends Component {

  render() {
    return (
      <div className="footerWrapper">
        <div className="footerLine"></div>
          <Container>

              <Row>
                <Col lg={8} sm={12}>
                <Link to="/">
                  <img
                    className="footerLogo"
                    draggable='false'
                    src={'./common/property-people-logo.svg'}
                    alt='Property People Care'
                  />
                </Link>
                  <p className="footerAddress">
                  <FontAwesomeIcon 
                    icon={faPhoneAlt} 
                    className="faIcon" 
                  /> 020 8946 7171<br/>
                  <FontAwesomeIcon 
                    icon={faEnvelope} 
                    className="faIcon" 
                  /> info@propertypeople.net<br/>
                    204 Tooting High Street, London, SW17 0SG<br/>
                  </p>
                </Col>
                <Col className="footerSocial">

                  <a href="https://twitter.com/Property_People" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon 
                    icon={faTwitter} 
                    className="faIcon" 
                  />
                  </a>

                  <a href="https://www.facebook.com/Property.People.London" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon 
                    icon={faFacebook} 
                    className="faIcon" 
                  />
                  </a>

                  <a href="https://www.linkedin.com/company/property-people-london" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon 
                    icon={faLinkedin} 
                    className="faIcon" 
                  />
                  </a>

                  <a href="https://instagram.com/propertypeoplecare" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon 
                    icon={faInstagram} 
                    className="faIcon" 
                  />
                  </a>  

                </Col>
              </Row>
              <Row>
                <Col lg={9} xs={12}>
                  <p className="footerCredits">© {new Date().getFullYear()} Property People London Ltd trading as Property People</p>
                </Col>
                <Col className="tr">
                  <p className="developer">Website developed by < a href="https://newwindowmarketing.co.uk/" target="_blank" rel="noopener noreferrer">NWM</a>.</p>
                </Col>
              </Row>

        </Container>
      </div>
    )
  }
}

export default withRouter (Footer)