import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class HowToReplaceToiletPage extends Component {

  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>How to replace a toilet seat</title>
        <meta name="description" content="How long does it take to install a new toilet seat? Believe it or not, it only takes two minutes to put everything in place." />
      </Helmet>

      <PageHeader 
        name="Blog" 
        img="/home/banner-sustainable.jpg"
        position="center"  
      />

      <Container>
        <Row>
          <Col>

            <h2 className="sectionHead">How to replace a toilet seat</h2>
            <img className="articleHeroImg" src="/blog/toilet-seat.jpg" alt="DIY spring renovation tips"/>
            <p className="article bold" style={{marginTop:"2rem"}}>How long does it take to install a new toilet seat? Believe it or not, it only takes two minutes to put everything in place. But, on the contrary to this, it actually takes longer to remove the old toilet seat. In fact, it can be quite frustrating because the bolts are often corroded. Some people simply give up because the nuts can’t be taken out anymore. However, you don’t have to worry anymore because the solution is at hand.</p>
            <p className="article">If after applying a lubricant the nut won’t come off, get a drill and a pair of safety glasses. Use a 1/16-inch drill bit to drill into the nut. Drill into the bolt up to a quarter inch in depth. In order to widen up the hole, use a 1/8-inch bit and afterward a 3/16-inch bit. After drilling, use the socket wrench once again. You want the bolt broken off while turning the nut. If the bolt still refuses to break, just work on the hole until the bolt breaks. That’s what you are aiming for.</p>
            <p className="article bold">How to deal with metal seat bolts using the deep-well socket option</p>
            <p className="article">If the bolts which you have to deal with are made of metal, don’t waste your energy trying to loosen them with a pair of pliers. Use a socket wrench instead that has a deep-well socket. The toilet seat that you have at home may require a half-inch socket, as the deep socket can tightly grip the nut.</p>
            <p className="article">When the bolts are made of metal they don’t usually have a flip-open cover. You may start the whole procedure by turning the nut counter-clockwise. You can apply all the force that you can muster when you twist it. You might end up breaking it if the bolt is made up of steel or brass and is badly corroded. Don’t worry too much if you do.</p>
            <p className="article">If the nut remains stubborn, use WD-40—a spray lubricant that deeply penetrates the joint you want to displace. Make sure to hold a rag behind the nut so that the overspray is contained. After 15 minutes, try loosening it again. If there is still no loosening, but the bolt spins anyway, move on to the other option.</p>
            <p className="article">If you find difficulties in doing this we are here to help. Call us on 02089467171 . We are based Wimbledon Park and we cover most of SW London.</p>
            
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (HowToReplaceToiletPage)