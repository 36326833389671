import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import './CallToAction.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

  class CallToAction extends Component {
  
    render() {
      return (
        <div className="ctaWrapper" style={{marginTop:"2rem"}}>
        <Row>
          <Col>
            {this.props.service ? (<p className="servicesExplainer bold">Anything not listed? We are competitively priced, call us for a quote.</p> ) : (null)}
          </Col>
        </Row>
        <Row className="ctaTxt">
          <Col>
            <h3 className="cta">Call us now</h3>
            <FontAwesomeIcon 
              icon={faPhoneAlt} 
              className="faIconCta" 
            />
            <h3 className="cta">020 8946 7171</h3>
          </Col>
        </Row>
        <Row className="ctaBtn">
          <Col>
            <Link to="quote">
              <button className="btn btn-primary">
                Instant Quote
              </button>
            </Link>
          </Col>
        </Row>
        </div>
      )
    }
}

export default withRouter (CallToAction)