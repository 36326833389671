import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import './Contact.css'
import Helmet from 'react-helmet'
import axios from 'axios'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'
import GoogleMapReact from 'google-map-react'

const MapWithMarker = ({ text }) => <img src="./contact/map-marker.jpg" alt="Property People" className="mapMarker"></img>

class ContactPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name:"",
      email:"",
      message:"",
      emailValidation:"",
      thanksOpacity:0,
      emailBtnPointerEvents:"all"
    };

    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  };
  
  static defaultProps = {
    center: {
      lat: 51.4235818,
      lng: -0.1751278
    },
    zoom: 12
  };

  onNameChange(e){
    this.setState({name: e.target.value});
  }

  onEmailChange(e){
    this.setState({email: e.target.value});
  }

  onMessageChange(e){
    this.setState({message: e.target.value});
  }

  handleSubmit(e){
    e.preventDefault()
    
    if(!(this.state.email).includes("@" && ".")){ // Handle email validation
      this.setState({emailValidation:"Please enter a valid email address"})
    } else {
      this.setState({
        emailBtnOpacity: 0,
        thanksOpacity: 1,
        emailBtnPointerEvents:"none",
        emailValidation:"",
      })

      let emailToMe = {
        "fromEmail" : "info@property-people.net",
        "toEmail" : "info@property-people.net",
        "subject": `${this.state.name} submitted a contact form from SW Carpet Cleaning`,
        "body":`<div style="font-size:18px; line-height:1.5;">
                <p>${this.state.name} submitted a contact form from SW Carpet Cleaning</p>
                Name: ${this.state.name} <br/>
                Email: ${this.state.email} <br/>
                <p> ${this.state.message} </p>
                </div>
                <div style="font-size:14px; padding-top:12px">
                  <i>This form was sent using property-people.net contact form.</i>
                </div>`
      }

      axios.post(`https://4088ea0788.execute-api.us-east-1.amazonaws.com/default/contactFormSend`, emailToMe)
      .then(res => {
        // console.log(res);
        // console.log(res.data)
        // console.log(emailToMe)
        console.log("Email sent")
    })


    }
      
  }
  

  render() {
    return (
      <div className="contactPageWrapper">

      <Helmet>
        <title>SW Carpet Cleaning - Contact</title>
        <meta name="description" content="SW Cartpet Cleaning - Contact" />
      </Helmet>

      <PageHeader 
        name="Contact" 
        img="/home/banner-sustainable.jpg"
        position="center"  
      />

      <Container>
        <Row>
          <Col lg={7}>
          <h2 className="sectionHead">Contact Us</h2>
          <p className="contactTxt">Our helpful staff are on hand to take calls and arrange work to be carried out at your property at a time that’s convenient for you. Please call us now on <strong>020 8946 7171</strong> or if you prefer, send us a message by filling in the contact form below.</p>
          <form 
          id="contactForm" 
          className="contactForm"
          onSubmit={this.handleSubmit}
          >
            <Row>
              <Col lg={6} sm={12}>
                <label htmlFor="Email">Email Address</label>
                <input type="text" onChange={this.onEmailChange}></input>
                <div id="emailValidation" className="validationBox">{this.state.emailValidation}</div>
              </Col>
              <Col lg={6} sm={12}>
                <label htmlFor="Name">Name</label>
                <input type="text" onChange={this.onNameChange}></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor="Name">Message</label>
                <textarea
                  rows={7}
                  onChange={this.onMessageChange}
                  maxlength="2000"
                />
                <button type="submit" className="btn btn-primary" style={{opacity:this.state.emailBtnOpacity, pointerEvents:this.state.emailBtnPointerEvents}}>
                  Send
                </button>
                <div className="submitThanks  pt-1" style={{opacity:this.state.thanksOpacity}}>
                  <strong>Thank you for your enquiry! Email is now on its way.</strong><br/>
                  If you have requested an appointment, we will get back to you as soon as possible.
                </div>
              </Col>
            </Row>
          </form>
          </Col>
          <Col lg={5}>
            <h4 className="mapCol">Find Us</h4>

            <p>020 8946 7171<br/>
            info@property-people.net<br/>
            204 Tooting High Street, London, SW17 0SG<br/></p>

            <div className="googleMap" style={{ height: '30rem', width: '100%' }}>
              
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDCuPvag1u-Ib-x3NKmxwjkuKjkjPSsOUc" }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
            >
              <MapWithMarker
                lat={51.4235053}
                lng={-0.1732492}
              />
            </GoogleMapReact>

            </div>
          </Col>
        </Row>
      </Container>
      
      </div>
    )
  }
}

export default withRouter (ContactPage)