import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class DiySpringRenovationPage extends Component {

  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>DIY spring renovation tips</title>
        <meta name="description" content="You will easily discover there is always something more you can do to improve your home . Be it gardening or repainting, you can pick up any task, apply your DIY skills, and put your hard work and creativity into fruition." />
      </Helmet>

      <PageHeader 
        name="Blog" 
        img="/home/banner-sustainable.jpg"
        position="center"  
      />

      <Container>
        <Row>
          <Col>

            <h2 className="sectionHead">DIY spring renovation tips</h2>
            <img className="articleHeroImg" src="/blog/diy-spring.jpg" alt="DIY spring renovation tips"/>
            <p className="article" style={{marginTop:"2rem"}}>Most people who are experienced in this job are great for asking about the projects. Initially I was very nervous, while asking question in hardware stores. Those days, I used to think that it may be stupid thing for some people. Today, it’s all right for me. “I am quite new in this project. How can I get this job done?” Though I know little bit about it but it is better to show that you do not know anything.</p>
            <p className="article">On the other hand, if you can’t make up your mind which project to take on first, then you may refer to our guide for some ideas on the most renovated areas in the home in terms of spring DIY.</p>
            <p className="article bold">Gardening and other outdoor improvements</p>
            <p className="article">During springtime, temperature begins increasing and daylight time starts becoming significantly longer thus, it is quite normal that you want to make the most of your time outside with the beautiful weather. On the other hand if you want to lounge outside but your outdoor area doesn’t look that welcoming (hint: unkempt lawn, growing weeds, and dense flowerbeds) then you might want to start getting down to work in improving it so you can finally be able to chill out in there.</p>
            <p className="article">As you know, spring renovation isn’t exclusive to remodelling or making over the inside of your home, and of course you can always, always find ways to improve your gardening skills or to simply test your green fingers.  The best thing about this is that there are tons of ways you can easily improve your yard or garden and that is – without having to spend a fortune. This then is a great option if you want to make significant improvements in home but on a tight budget.</p>
            <p className="article">You can get down to work right away and pull out the weeds starting to peep through the soil, trim the bushes, and remove any dried or fallen leaves or twigs.</p>
            <p className="article">It’s also a good idea to assess your outdoor space for any unsightly damage such as broken fences, peeling paintwork, or fallen trellises. Fixing these will instantly make a significant difference as to how your garden both looks and feels.</p>
            <p className="article">Finally, you might also want to consider painting your fence or grills in vibrant color as well as making a few inexpensive additions. This could be as simple as hanging a beautiful wind-chime you made yourself or a mirror to make the space look wider.</p>
            <p className="article">If you have lots of time and patience for it, you can also add a patio or deck which is highly recommended if you have special skills in carpentry that you have always wanted to show off. Adding an outdoor dining space is especially a good idea to bring some indoors out, dine and relax with a bit of fresh air.</p>
						<p className="article bold">Knock-through and rearrangement projects</p>
						<p className="article">If you have a larger budget, then doing a major renovation in your home can absolutely make a huge or high-impact difference. Doing major renovations can do great wonders to your home not just aesthetically but also practically, for instance creating an open-plan living space.  Knocking down walls is a lot cheaper than building them so this could be a very wise decision in order to easily transform your home no matter what size of budget.</p>
						<p className="article">You can experiment and conjoin several rooms in your house into one huge living space where everyone in your household can do anything – watching TV, eating a meal, or cooking.</p>
						<p className="article">Alternatively, you can also rearrange or repurpose some rooms in your house so that the spaces would be more useful. For instance you can create a play space for kids or a home office out of your unused/ spare dining room, or renovate your attic (if you have one) and transform it into a guest bedroom or bathroom or just about anything else you might fancy other than a dull, dingy storage space.</p>
						<p className="article bold">Painting and decorating</p>
						<p className="article">Adding a fresh coat of paint or replacing your wallpaper is the easiest and fastest way to give your interiors a brilliant makeover. Note: it also doesn’t have to be expensive.</p>
						<p className="article">If your budget is a little bit limited, then you can paint just one wall of a room in your house for some accent. Choose a bright new color and it will instantly give the room a boost. The best thing about painting your walls or just about anything in spring is that the climate is warmer so that windows can be left open to let the breeze in speed up paint-drying.</p>
						<p className="article">Clean up any accumulated dirt, remove webs, and also replace your curtains. You can also paint your ceilings a fresh coat of crisp white or other similar light neutral color to make the walls looks taller.</p>
						<p className="article">Just remember, if you’re going to be the one to do all the redecorating, make sure that it is a thorough job otherwise, the result of all your efforts will just prove wasteful and worthless. You can hire a professional painter instead to get the job done right and fast and as with wallpapering, it is a trickier task and wallpaper can be very expensive so you can’t make any mistakes in installing them.</p>
						<p className="article">There are yet other ways to redecorate your house and it can be anything from buying (or making your own) new curtains, window blinds, or having your furniture reupholstered. Nonetheless, redecorating or renovating in a perfect spring weather is always a good option no matter what type of project or budget.</p>
						<p className="article">If you find difficulties in getting your diy project done we are happy to help. We are based in Wimbledon Park and cover most of the SW London. Call us now on  02089467171.</p>
            
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (DiySpringRenovationPage)