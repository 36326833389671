import React, { Component } from 'react';
import Slider from 'react-slick'
import styled from 'styled-components';
import './PortfolioItem.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Img = styled.img`
  height:25rem;
  margin: 0 auto;
`;

const GreyWrapper = styled.div`
  background: #dee2e6;
`;

const ImgWrapper = styled.div`
  height:25rem;
  overflow:hidden;
  text-align:center;
  background:url(/portfolio/logo-bw.png);
  background-repeat:no-repeat;
  background-position:center;
`;

// const ImgWrap = styled.div`
//   height:25rem;
//   background:url(${props => props.img});
//   background-position:center;
//   background-size:cover;
// `;

class PortfolioItem extends Component {
  render() {

    const GallerySettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows:true,
      lazyLoad: true,
      // autoplay:true,
    }

    const ListGalleryImages = this.props.img.map((item, index) => 
      <GreyWrapper key={index}>
        <ImgWrapper>
          <Img src={item.link} alt={this.props.title}/>
        </ImgWrapper>
      </GreyWrapper>
    );

    return (
      <Row style={{marginTop:"3rem"}}>
        <Col lg={6}>
          <Slider {...GallerySettings}>
            {ListGalleryImages}
          </Slider>
        </Col>

        <Col lg={6}>
          <h2 className="sectionHead" style={{paddingTop:"0"}}>{this.props.title}</h2>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default PortfolioItem;