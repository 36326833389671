import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class HandymanPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Non Skilled Light Work",
      "Putting Together Furniture Requiring some Assembly",
      "Moving Heavy Items",
      "Gardening",
      "Replacing Locks & Door Handles"
    ];

    const pricingItems = [
      {"service":"", "price1":"Monday-Friday 8am-6pm", "price2":"Evenings & Weekends"},
      {"service":"General Handyman Work", "price1":"£59 / hour", "price2":"£79 / hour"},
    ];

    const listServiceItems = serviceItems.map((item, index)=>
      <li className="services" key={index}>
        <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
        {item}
      </li>
    )

    const listPricingItems = pricingItems.map((item, index)=>
      <Row key={index}>
        <Col lg={3} xs={4} className="pricing">{item.service}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price1}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price2}</Col>
      </Row>
    )

    return (
      <div className="servicesPageWrapper servicesHandyman">

        <Helmet>
          <title>Property People Care - Handyman</title>
          <meta name="description" content="Handyman - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Handyman" 
          img="/home/banner-handyman.jpg"
          position="center"
          color="var(--primary-blue)"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Handyman</h2>
            <p className="servicesExplainer bold">Fully insured Handyman that will follow your instructions. Need someone who is good with screws, nuts & bolts? Need help with putting together a cupboard or desk? call us!</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Pricing</h2>
              {listPricingItems}
            </Col>
          </Row>

          <CallToAction service={true} />

        </Container>
      </div>
    );
  }
}

export default HandymanPage;