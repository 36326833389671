import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import '../Blog.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Helmet from 'react-helmet'
import PageHeader from '../../../components/PageHeader'
import YouTube from 'react-youtube'
import CallToAction from '../../../components/CallToAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

class ScienceBehindDeepCleaningCarpetsPage extends Component {

  async componentDidMount() {
    document.getElementById("blog").classList.add("activeMenu");
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    document.getElementById("blog").classList.remove("activeMenu")
  }

  render() {
    return (
      <div className="articlePageWrapper">

      <Helmet>
        <title>Science behind the need for deep cleaning your carpets</title>
        <meta name="description" content="Let’s apply the 5 second rule to test your knowledge of how clean you think household surfaces are." />
      </Helmet>

      <PageHeader 
        name="Blog" 
        img="/home/banner-sustainable.jpg"
        position="center"  
      />

      <Container>
        <Row>
          <Col>
            <h2 className="sectionHead">Science behind the need for deep cleaning your carpets</h2>
            <img className="articleHeroImg" src="/blog/science.jpg" alt="The Monsters in My Carpet" style={{width:"60%"}}/>
            <p className="article-big bold">Let’s apply the 5 second rule to test your knowledge of how clean you think household surfaces are.</p>
            <p className="article">If your Favourite biscuit dropped on these surfaces, after having rescued it, which one would you eat?</p>
            <p className="article">Would it be the one from?</p>
            <ol className="bold">
              <li>The street outside.</li>
              <li>The toilet seat.</li>
              <li>Your dry cleaned carpet.</li>
              <li>Your vacuumed carpet.</li>
            </ol>
            <p className="article">You are probably the safest with the toilet seat and then the street outside. Here are the disgusting yet cold hard facts.</p>
            
            <ol>
              <li>Research shows British household carpets are 5 times filthier than the streets outside the front door.</li>
              <li>Research conducted by Philip Tierno Jr., Ph.D., a microbiologist and immunologist at New York University Langone Medical Center and the author of The Secret Life of Germs indicates your carpet is likely to be <span className="bold">4,000 times as dirty</span> as your toilet seat.</li>
              <li>The dry cleaned carpet sounds great until you look closer at the chemicals used. If you told the fire brigade which chemicals were used to dry clean your carpet, they would probably turn up in hazmat suits and gas masks to clean up.</li>
              <li>Your everyday vacuumed carpet accumulates 1kg of filth pressured down below per square foot of top surface.</li>
            </ol>

            <p className="article">So what should you do to keep you carpet cleaned if simple vacuuming wouldn’t do?</p>
            <p className="article">The answer is a <span className="bold">hot water extraction deep carpet cleaning.</span><Link to={"carpet-cleaning"}> (using a commercial carpet cleaning machine operated by professional carpet cleaners.)</Link></p>
            <p className="article">We often use this video from the Richard &amp; Judy show as a reference to back up our claims. If you take a few minutes to watch it you will see the horrific facts behind how dirty your carpet is at a microscopic level.</p>
            
            <YouTube videoId="dTpjc-rXG0A" />
            
            <p className="article">Simply put, your carpet is probably 5 times dirtier than the street outside your front door & 4000 times filthier than the toilet seat. Scary!</p>
            <p className="article">So after having watched this video you would agree how crazy it is that we clean the toilet seat every day yet not deep clean our carpets for years when they are 4000 times filthier that the toilet seat.</p>
            <p className="article">So to recap…..</p>
            <p className="article bold">The facts about typical British carpet despite vacuuming:</p>
            <ol className="bold">
              <li>5 times filthier than the pavement outside</li>
              <li>1kg of filth hidden below every sq. ft.</li>
              <li>200,000 bacteria live every sq. inch</li>
              <li>90% have never been deep cleaned</li>
              <li>Should be deep cleaned every 3 months</li>
            </ol>
            <p className="article">You may think well, why don’t I just change my carpet to laminate, or tile flooring. In the video above Dr Schubert Pereira answers that question. An average human being breaths in about 50 pounds of air per day. You carpet acts as a filter and without it, you would be breathing in a lot of that is filth trapped in your carpet. So the answer is not to get rid of your carpet but to have it deep cleaned using a <Link to={"carpet-cleaning"}>hot water extraction method</Link> such as the one utilised by professional Property People carpet cleaners using a commercial carpet cleaning machine.</p>
            <p className="article">If you are confused about hot water extraction method, steam cleaning, shampooing, dry cleaning…. don’t be. Hot water extraction method is the correct one for most UK household carpets. Read our blog here to learn the differences.</p>
          </Col>
        </Row>

        <CallToAction />

        <Link to="blog">
          <FontAwesomeIcon icon={faAngleLeft}/> Back to blog
        </Link>

      </Container>
      
      </div>
    )
  }
}

export default withRouter (ScienceBehindDeepCleaningCarpetsPage)