import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import './Blog.css'
import Helmet from 'react-helmet'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'

class BlogPage extends Component {

  async componentDidMount() {
    if(!document.getElementById("blog").classList.contains("activeMenu")){
      document.getElementById("blog").classList.add("activeMenu");
    }
    window.scroll(0, 0);
  }

  render() {

    const blogArticles = [
      
        {"title":"Science behind the need for deep cleaning your carpets",
        "img":"/blog/science.jpg",
        "excerpt":"Let’s apply the 5 second rule to test your knowledge of how clean you think household surfaces are. If your Favourite biscuit dropped on these surfaces, after having rescued it, which one would you eat? Would it be the one from?",
        "link":"/science-behind-deep-cleaning-carpets"},
      
        {"title":"How to… DIY tips for beginners",
        "img":"/blog/diy-tips.jpg",
        "excerpt":"Most people who are experienced in this job are great for asking about the projects. Initially I was very nervous, while asking question in hardware stores. Those days, I used to think that it may be stupid thing for some people. Today, it’s all right for me. “I am quite new in this project. How can I get this job done?” Though I know little bit about it but it is better to show that you do not know anything.",
        "link":"/how-to-diy-tips-for-beginners"},

        {"title":"DIY spring renovation tips", 
        "img":"/blog/diy-spring.jpg",
        "excerpt":"Spring season is that perfect time of the year to renovate your home no matter how much you have in your budget. You will easily discover there is always something more you can do to improve your home . Be it gardening or repainting, you can pick up any task, apply your DIY skills, and put your hard work and creativity into fruition.",
        "link":"/diy-spring-renovation-tips"},

        {"title":"How to fix a tap", 
        "img":"/blog/fix-tap.jpg",
        "excerpt":"If you are facing a tap-related problem the first thing you need to figure out is what type of valve you’re working with, since water taps are traditionally designed with three kinds of valves. The first type is a traditional one, which involves use of rubber washers placed at compression valve’s end located within the tap. The second type uses a washer mechanism. This is quite a modern approach that utilizes a ceramic disc.",
        "link":"/how-to-fix-a-tap"},

        {"title":"How to replace a toilet seat", 
        "img":"/blog/toilet-seat.jpg",
        "excerpt":"How long does it take to install a new toilet seat? Believe it or not, it only takes two minutes to put everything in place. But, on the contrary to this, it actually takes longer to remove the old toilet seat. In fact, it can be quite frustrating because the bolts are often corroded. Some people simply give up because the nuts can’t be taken out anymore. However, you don’t have to worry anymore because the solution is at hand.",
        "link":"/how-to-replace-a-toilet-seat"},
    
    ]

    const listBlogArticles = blogArticles.map((item)=>
      <div className="blogArticleShortWrap" key={item.title}>
        <Link to={item.link}>
          <h2 className="sectionHead">{item.title}</h2>
          <div className="blogPageImg" style={{backgroundImage:`url(${item.img})`}}></div>
        </Link>
        <p className="blogExcerpt">{item.excerpt}</p>
        <Link to={item.link}>
          <p className="readMore">Continue Reading</p>
        </Link>
      </div>
    )

    return (
      <div className="blogPageWrapper">
      <Helmet>
        <title>SW Carpet Cleaning - Blog</title>
        <meta name="description" content="SW Cartpet Cleaning - Blog" />
      </Helmet>

      <PageHeader 
        name="Blog" 
        img="/home/banner-sustainable.jpg"
        position="center" 
      />

      <Container>
        <Row>
          <Col lg={9}>
            {listBlogArticles}
          </Col>
        </Row>        
      </Container>
      </div>
    )
  }
}

export default withRouter (BlogPage)