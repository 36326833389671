import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class DampProofingPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Damp Treatment",
      "Damp Proofing",
      "Plastering Work",
    ];

    const listServiceItems = serviceItems.map((item)=>
    <li className="services" key={item}>
      <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
      {item}
    </li>
  )

    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Damp Proofing</title>
          <meta name="description" content="Damp Proofing - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Damp Proofing" 
          img="/home/banner-damp.jpg"
          position="center"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Damp Proofing</h2>
            <p className="servicesExplainer bold">Untreated damp can be hazardous to health and if the problem is not dealt correctly, it will return. Property People Damp expert will treat the root cause of the problem, re-plaster the area to make it look new, clean up after himself and provide a 3 year guarantee. If the problem returns so will we.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
        </Row>

          <CallToAction service/>

        </Container>
      </div>
    );
  }
}

export default DampProofingPage;