import React, { Component } from 'react'
import {
    // Link,
    withRouter
  } from 'react-router-dom'
import './Testimonial.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

  class Testimonial extends Component {
  
    async componentDidMount() {}
  
    render() {
      return (
        <div className="testimonialWrapper">
            <Row>
                <Col xs={1}><FontAwesomeIcon icon={faQuoteLeft} className="quoteIcon" /></Col>
                <Col>
                <p className="testimonialBody">{this.props.body}</p>
                <p className="testimonialAuthor">{this.props.author}</p>
                </Col>
            </Row>

        </div>
      )
    }
}

export default withRouter (Testimonial)