import React, { Component } from "react";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import "./Testimonials.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageHeader from "../../components/PageHeader";
import Testimonial from "../../components/Testimonial";
import GoogleWidget from "../../components/GoogleWidget";

class TestimonialsPage extends Component {
  render() {
    return (
      <div className="testimonialsPageWrapper">
        <Helmet>
          <title>SW Carpet Cleaning - Testimonials</title>
          <meta name="description" content="SW Cartpet Cleaning - Testimonials" />
        </Helmet>

        <PageHeader name="Testimonials" img="/home/banner-sustainable.jpg" position="center" />

        <Container className="pt-4">
          <Row>
            <Col>
              <Testimonial
                body="Our experience with Property People has been wholly positive. Our agent responded quickly to our queries and made moving simple and straightforward. Would definitely recommend!"
                author="Emily Corfield"
              />
              <Testimonial
                body="Excellent customer service! Oliver was very knowledgeable and friendly in helping choose the right property for me. Would definitely recommend this place!"
                author="Jack Davies"
              />
              <Testimonial
                body="Great customer service and excellent response times, everything went as planned. Special thanks to Oliver who made the transition to my new property smooth and without any complications."
                author="Pablo Taddeo"
              />
              <Testimonial
                body="Very professional and organised business. Excellent customer service with quick response and interest shown by staff."
                author="Gabriel Ivanov"
              />
            </Col>
            <Col lg={6} sm={12}>
              <Testimonial
                body="A fantastic and professional property management. Very knowledgable about what they are doing and offered a very nice experience throughout.
              The process was very easy and will definitely recommend you go through them whether its buying or renting!"
                author="Erica"
              />
              <Testimonial
                body="We are very happy with Property People service. Asad  has been very kind and proffesional and made the process easy and quick. We are excited with our new flat and agent, and would recommend them without reservations."
                author="Eva Guadalupe Hernández Donoso"
              />
              <Testimonial
                body="We moved to London from Liverpool and were working with a lot of estate agents to find the right place. Property people were extremely helpful and accommodating from the start treating us as people rather than corporate clients like other estate agents in the area. Natalie has been a pleasure; always friendly and helpful. We finally rented a property with Property People and the service from them has been great. If we have a problem with the flat Natalie sorts it out straight away with no hesitation. We would definitely recommend Property People."
                author="Hannah Southwell"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="read-all">
                Read all our Google reviews or leave your own{" "}
                <strong>
                  <a
                    href="https://www.google.com/search?hl=en-GB&gl=uk&q=Property+People,+204+Tooting+High+St,+Tooting,+London+SW17+0SG&ludocid=12094226980304067076&lsig=AB86z5VD1l_HI-ABSrD84Hzltbes#lrd=0x48760f504d12694b:0xa7d7535c51f81a04,1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                </strong>
                .
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>
                Property People always appreciate all feedback.
                <br />
                To send us feedback or submit a testimonial just email us here:{" "}
                <a href="mailto:info@property-people.net">info@property-people.net</a>
              </h5>
            </Col>
          </Row>

          <GoogleWidget />
        </Container>
      </div>
    );
  }
}

export default withRouter(TestimonialsPage);
