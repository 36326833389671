import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

class PlumbingPage extends Component {

  async componentDidMount() {
    window.scroll(0, 0);
    document.getElementById("services").classList.add("activeMenu")
  }

  async componentWillUnmount() {
    document.getElementById("services").classList.remove("activeMenu")
  }

  render() {

    const serviceItems = [
      "Plumbing Emergencies & Repairs",
      "Boiler Servicing, Repairs & Installations",
      "Drain Unblocking & Jetting",
      "Heating",
      "Bathroom Installations"
    ];

    const pricingItems = [
      {"service":"", "price1":"Monday-Friday 8am-6pm", "price2":"Evenings & Weekends"},
      {"service":"Plumbing & Gas works", "price1":"£79 / hour", "price2":"£120 / hour"},
      {"service":"Drain Unblocking", "price1":"£140 / hour", "price2":"£200 / hour"},
      {"service":"Gas Safety Check", "price1":"£79", "price2":"£120"},
      {"service":"Boiler Service", "price1":"£79", "price2":"£120"},
      {"service":"Gas Safety Check & Bolier Service", "price1":"£139", "price2":"£199"},
      {"service":"Power Flushing", "price1":"from £499", "price2":"from £699"},
    ]

    const listServiceItems = serviceItems.map((item, index)=>
      <li className="services" key={index}>
        <FontAwesomeIcon icon={faCaretRight} className="servicesIcon"/>
        {item}
      </li>
    )

    const listPricingItems = pricingItems.map((item, index)=>
      <Row key={index}>
        <Col lg={3} xs={4} className="pricing">{item.service}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price1}</Col>
        <Col lg={3} xs={4} className="pricing tr">{item.price2}</Col>
      </Row>
    )

    return (
      <div className="servicesPageWrapper">

        <Helmet>
          <title>Property People Care - Plumbing</title>
          <meta name="description" content="Plumbing - Property People Care" />
        </Helmet>

        <PageHeader 
          name="Plumbing" 
          img="/home/banner-plumbing.jpg"
          color="var(--primary-blue)"
          position="center"
        />

        <Container>
          <Row>
            <Col lg={9}>
            <h2 className="sectionHead">Plumbing</h2>
            <p className="servicesExplainer bold">Gas safe registered, fully insured, qualified local plumbers that arrive on time and fix the problem at reasonable rates. You will never be overcharged and we will provide a guarantee for the work carried out.</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Services Offered</h2>
              {listServiceItems}
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="sectionHead">Pricing</h2>
              {listPricingItems}
            </Col>
          </Row>
					<Row>
            <Col lg={9}>
              <h2 className="sectionHead">Gas Safety Checks £79.00</h2>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
              <p className="servicesExplainer light">We work with Landlords and Letting Agents to provide safe and regularly maintained gas installations for their tenants. Our Gas Safe registered engineers can also provide landlords gas safety inspections and certificates.</p>
            </Col>
						<Col lg={3}>
							<img src="/services/gas-safe.jpg" style={{width:"5rem"}} alt="Gas Safe registered"/>
						</Col>
          </Row>
          <Row>
            <Col lg={9}>
              <h2 className="sectionHead">Boiler Service £79.00</h2>
              <p className="servicesExplainer light">Annual Boiler Servicing for all makes of Gas Combi and Regular Boilers</p>
              <p className="servicesExplainer light">By using Property People to service your boiler on a regular basis we can ensure that it is running at its optimum efficiency, therefore saving you money on your heating bills. Having your central heating serviced regularly can also greatly increase the lifespan of your boiler, again leading to considerable long term savings.</p>
            </Col>
          </Row>
          <Row>
            <Col lg={9}>
              <h2 className="sectionHead">Power Flushing from £499.00 <span className="light">(up to 8 radiators)</span></h2>
              <p className="servicesExplainer light">The build up of sludge in hot water central heating systems is inevitable, no matter what fuel source is used. Power flushing the system can remedy all of these problems and add life expectancy to your central heating system. It may also shave off a 1/4 off your energy bills.</p>
              <p className="servicesExplainer light">Over a period of time this sludge can block pipes and prevent the radiators from distributing heat around your house. Sludge can seriously affect the efficiency of your central heating system, and will lead to higher fuel bills and can bring forward the need to replace some or all of the system.</p>
            </Col>
          </Row>
          <Row>
            <Col lg={9}>
              <h2 className="sectionHead">Boiler & Bathroom Installations</h2>
              <p className="servicesExplainer light">Competitively priced, call us for a quote!</p>
            </Col>
          </Row>
					<Row>
            <Col lg={9}>
              <h2 className="sectionHead">Accredited Installers</h2>
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
              <p className="servicesExplainer light">Our Plumbing & heating Engineer is trained to the highest standards by baxi to install and commission all of their products. By choosing us as a your baxi Accredited Installer you will benefit from an exclusive 7 year parts and labour warranty.</p>
            </Col>
						<Col lg={3}>
							<img src="/services/baxi.jpg" style={{width:"80%"}} alt="Baxi approved"/>
						</Col>
          </Row>
          <Row>
            <Col lg={9}>
              <h2 className="sectionHead">Get the best deal on warranties with works</h2>
              <p className="servicesExplainer light">We know that our clients value a great warranty package and that’s why here at Property People, we want to make sure that you get the best deal when it comes to warranties.</p>
              <p className="servicesExplainer light">There are many different types of warranty available including standard warranties, warranty offers and extended warranties. You can call us to find out what warranty options is best for you and which are available on a range of Boilers. Our Engineer would love to discuss all options with you.</p>
							<p className="servicesExplainer light">All warranties are supported by <strong>heateam</strong>, the award-winning aftersales service so you can be sure that own customer, and their boiler, will be looked after throughout the warranty period.</p>
							<p className="servicesExplainer light">The Baxi Duo-tec Combi HE A now comes with a fantastic 7 year warranty, and the Baxi Platinum Combi HE A is available with an incredible 10 year warranty. These warranty offers are only available to us as we are a baxi registered installer.</p>
            </Col>
          </Row>

          <CallToAction service={true} />

        </Container>
      </div>
    );
  }
}

export default PlumbingPage;

// Comp1
// Picture
// Main Text

// Comp2
// Fees

// Comp3
// Services Offered

// Comp 4
// Services Offered title+text

//Comp 5
// Contact Footer