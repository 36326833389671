import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class NotFound extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col style={{height:"65vh"}}>
                        <h2 style={{
                            textAlign: "center",
                            padding:"16rem 1rem 13rem 1rem",
                            color:"var(--primary-blue)"
                        }}>Page not found :(</h2>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default NotFound;