import React, { Component } from 'react'
import './ModalPopup.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class ModalPopup extends Component {

    render() {
        return (
            <div className="modalWrapper" style={{opacity: this.props.opacity, pointerEvents: this.props.pointerEvents}}>
                <div className="modalBox">
                    <div className="closeX"><FontAwesomeIcon icon={faTimesCircle} className="closeXsymbol" onClick={this.props.closeModal}/></div>
                  <h2 className="modalTitle">BOOK NOW<br/>and get a <span>10% discount</span><br/>on your quote!</h2>
                  <button 
                    className="btn btn-primary" 
                    onClick={this.props.onClick}
                    >
                    
                    Apply Discount Now

                  </button>
                </div>
            </div>
        );
    }
}

export default ModalPopup;