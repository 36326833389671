import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Home.css";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";
import GoogleWidget from "../../components/GoogleWidget";
import styled from "styled-components";

const SingleBannerWrap = styled.div`
  height: 28rem;
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.img});
`;

const BannerTitle = styled.h2`
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding: 0.2rem;
  margin-top: 2rem;
  background: var(--primary-blue);
  width: 50%;
  margin: 0 auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const CTABannerWrap = styled.div``;

const CTABanner = styled.div`
  background: #ffffffbd;
  margin-top: -7rem;
  padding: 2rem;
  width: 50%;
  border-radius: 10px;
  z-index: 5;
  position: absolute;
  top: 32rem;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  & h2 {
    color: var(--primary-blue);
    padding-bottom: 1rem;
    margin-top: -0.4rem;
  }
  @media (max-width: 576px) {
    width: 90%;
    margin-top: -50%;
  }
`;

const BlogImg = styled.div`
  background: url(${(props) => props.img});
  height: 15rem;
  background-size: cover;
`;

const Banners = [
  { title: "Sustainable Refurbishment", img: "/home/banner-sustainable.jpg" },
  { title: "Extensions", img: "/home/banner-extensions.jpg" },
  { title: "Painting", img: "/home/banner-painting.jpg" },
  { title: "Plumbing", img: "/home/banner-plumbing.jpg" },
  { title: "Electrics", img: "/home/banner-electrics.jpg" },
  { title: "Damp Proofing", img: "/home/banner-damp.jpg" },
  { title: "Handyman", img: "/home/banner-handyman.jpg" },
];

const Blogs = [
  {
    title: "How To... DIY Tips For Beginners",
    link: "/how-to-diy-tips-for-beginners",
    img: "/blog/diy-tips.jpg",
  },
  { title: "How To Fix A Tap", link: "/how-to-fix-a-tap", img: "/blog/fix-tap.jpg" },
  {
    title: "How To Replace a Toilet Seat",
    link: "/how-to-replace-a-toilet-seat",
    img: "/blog/toilet-seat.jpg",
  },
];

class Home extends Component {
  async componentDidMount() {
    var trustbox = document.getElementById("trustbox");
    window.Trustpilot.loadFromElement(trustbox);
  }

  render() {
    const ListBanners = Banners.map((item, index) => (
      <SingleBannerWrap img={item.img} key={index}>
        <Container>
          <Row>
            <Col>
              <BannerTitle>{item.title}</BannerTitle>
            </Col>
          </Row>
        </Container>
      </SingleBannerWrap>
    ));

    const heroSliderSettings = {
      dots: true,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
    };

    const ListBlogs = Blogs.map((item, index) => (
      <Col md={12} lg={4} className="blogBox" key={index}>
        <Link to={item.link}>
          <h3>{item.title}</h3>
          <BlogImg img={item.img} />
        </Link>
      </Col>
    ));

    return (
      <div>
        <Helmet>
          <title>Property People Care - Home</title>
          <meta name="description" content="Property People Care" />
        </Helmet>

        <CTABannerWrap>
          <CTABanner>
            <h2>GET A QUOTE NOW</h2>
            <Link to="quote" style={{ marginRight: "1rem" }}>
              <button className="btn btn-primary">Instant Quote</button>
            </Link>
          </CTABanner>
        </CTABannerWrap>

        <div className="bannerSection">
          <Slider {...heroSliderSettings}>{ListBanners}</Slider>
        </div>
        <Container>
          <Row>
            <Col md={12} lg={4} className="infoBox boxLight">
              <div className="titleBox">
                <h2 className="whoWeAre">Who We Are</h2>
              </div>
              <p>
                We are a mid-size and well-established refurbishment and construction company in
                London. We pride in being a multi services company with the vision to provide
                sustainable, high standard, affordable, professionals services.{" "}
              </p>
              <p>
                We have the skills and experience, necessary to know how to manage and execute the
                projects in the most efficient and effective way regardless of how complex they
                might be.
              </p>
              <p style={{ fontWeight: "500" }}>We always strive for perfection in our work!</p>
            </Col>
            <Col md={12} lg={4} className="infoBox boxDark">
              <div className="titleBox">
                <h2 className="whatWeDo">What We Do</h2>
              </div>
              <p>
                We provide a complete range of property refurbishment and construction services.{" "}
              </p>
              <p>
                Ranging from Refurbishment projects, Extensions/ Side returns, Loft Conversions,
                Kitchen and Bathrooms, Machinal & Electrical works, Patios and driveways.
              </p>
              <p>Have a project in mind that you’d like to discuss?</p>
              <p>
                <Link to="quote">Request a quote or a call back now!</Link>
              </p>
            </Col>
            <Col md={12} lg={4} className="infoBox boxLight">
              <div className="titleBox">
                <h2 className="whereWeDo">Where We Do It</h2>
              </div>
              <p>
                With Property People Care Ltd as your contractor, you’ll find us bringing a spirited
                exuberance to your project.
              </p>{" "}
              <p>
                You’ll see us involved in every stage; Designing, planning and execution of the
                project and you’ll benefit from our experience, skills and expertise. These are the
                qualities; I expect from my company because our customers expect them from us.{" "}
              </p>
              <p style={{ fontWeight: "500" }}>
                We don’t just talk about but go the extra mile for our clients.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="covidBox">
                <a
                  href="./home/staying-covid-19-secure-2020-230720.pdf"
                  className="covidNotice"
                  target="_blank"
                >
                  Covid-19 Notice
                </a>
              </div>
            </Col>
          </Row>

          <Row className="pageSection">{ListBlogs}</Row>

          <GoogleWidget />
        </Container>
      </div>
    );
  }
}

export default withRouter(Home);
