import React, { Component } from 'react';
import Helmet from 'react-helmet'

import Container from 'react-bootstrap/Container'

import PageHeader from '../../components/PageHeader'
import PortfolioItem from '../../components/PortfolioItem'

class PortfolioPage extends Component {

  render() {

    return (
			<div className="portfolioPageWrapper">
      <Helmet>
        <title>Property People Care - Portfolio</title>
        <meta name="description" content="Portfolio - Property People Care" />
      </Helmet>

			<PageHeader 
				name="Portfolio" 
				img="/home/banner-sustainable.jpg"
				position="center" 
			/>
			<Container>

				<PortfolioItem
					title="Conversion of a one bed apartment into a smart 2 Bed apartment"
					img={[
						{"link":"/portfolio/p1-29598091897_8a698d075d_b.jpg"},
						{"link":"/portfolio/p1-29598092197_55c66a68e9_b.jpg"},
						{"link":"/portfolio/p1-30666064398_305f380d02_b.jpg"},
						{"link":"/portfolio/p1-30666065098_cf68bb6c31_b.jpg"},
						{"link":"/portfolio/p1-42726848790_9c32f0908a_b.jpg"},
						{"link":"/portfolio/p1-43626815215_4d0f1fc37f_b.jpg"},
						{"link":"/portfolio/p1-43626817255_9065408ec1_b.jpg"},
						{"link":"/portfolio/p1-44485959052_919dcf3bca_b.jpg"},
						{"link":"/portfolio/p1-44485961322_1f85ff6bc3_b.jpg"},
						{"link":"/portfolio/p1-44485962292_dd35174e7a_b.jpg"},
						{"link":"/portfolio/p1-44485963232_a9910ae084_b.jpg"},
						{"link":"/portfolio/p1-44535756901_8caf010e44_b.jpg"},
						{"link":"/portfolio/p1-44535758351_496a7218cf_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Client budget was only £32000 and added value of £100000.</p>
				<p className="servicesExplainer">Few layout changes. Lounge converted into a new open plan kitchen/living room. Old kitchen converted into a double bedroom.</p>
				<ul>
					<li>New boiler and plumbing</li>
					<li>New electric wiring</li>
					<li>New flooring</li>
					<li>Complete painting decorating</li>
				</ul>
				<p className="servicesExplainer">Job done! £32k spend, added value £100000.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Sustainable refurbishment"
					img={[
						{"link":"/portfolio/p2-1d04e52974d_b.jpg"},
						{"link":"/portfolio/p2-42586312955_437daa824f_b.jpg"},
						{"link":"/portfolio/p2-42586313285_873dc0049e_b.jpg"},
						{"link":"/portfolio/p2-42586314475_b07e9dbab6_b.jpg"},
						{"link":"/portfolio/p2-42586315815_63a0c156a9_b.jpg"},
						{"link":"/portfolio/p2-42586317235_401256ee35_b.jpg"},
						{"link":"/portfolio/p2-42586318325_8f43b5c500_b.jpg"},
						{"link":"/portfolio/p2-42586320825_33618a9b5f_b.jpg"},
						{"link":"/portfolio/p2-42774259334_233037ef5d_b.jpg"},
						{"link":"/portfolio/p2-42774261234_79d165aeb8_b.jpg"},
						{"link":"/portfolio/p2-42774261454_f55e3bfa47_b.jpg"},
						{"link":"/portfolio/p2-42774261844_f11906238f_b.jpg"},
						{"link":"/portfolio/p2-42774262384_474796a6d9_b.jpg"},
						{"link":"/portfolio/p2-43443126312_a188cbe86c_b.jpg"},
						{"link":"/portfolio/p2-43443126602_343fb3ce78_b.jpg"},
						{"link":"/portfolio/p2-43443127712_896ab28c13_b.jpg"},
						{"link":"/portfolio/p2-43443132692_ab3def7267_b.jpg"},
						{"link":"/portfolio/p2-43490858161_8c9c156bd9_b.jpg"},
						{"link":"/portfolio/p2-43490858441_53ec4faf4b_b.jpg"},
						{"link":"/portfolio/p2-43490858631_2575ec3cbe_b.jpg"},
						{"link":"/portfolio/p2-43490860281_4ce75d32de_b.jpg"},
						{"link":"/portfolio/p2-43490860621_a11cb13a94_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Low quality properties can create many problems for occupants including high utility bills and a wide variety of negative health impacts resulting from cold, damp and poorly ventilated homes. By refurbishing existing properties to a good standard of sustainable design, running costs will be lower, the internal environment healthier and as a result it will be a more desirable place to live/work for the occupants, potentially increasing value.</p>
				<p className="servicesExplainer">When a property is being renovated, whether to bring it up to a modern liveable standard or to convert a large property into flats, it is a good time to consider what wider opportunities there are for improving the home and making it more economically, socially and environmentally sustainable.</p>
				<p className="servicesExplainer">Whenever we undertake a refurbishment project we always tried our best to significantly enhance the value of the property and improve the internal environment for the occupants.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Neglected and overgrown back garden renovation"
					img={[
						{"link":"/portfolio/p3-43448077341_d2e5bf1b9c_b.jpg"},
						{"link":"/portfolio/p3-43448077841_4679ccf74d_b.jpg"},
						{"link":"/portfolio/p3-43448078101_d507f255f4_b.jpg"},
						{"link":"/portfolio/p3-43448078151_3f9568f335_b.jpg"},
						{"link":"/portfolio/p3-43448078421_b0de5ac07e_b.jpg"},
						{"link":"/portfolio/p3-43448079261_99e08a2282_b.jpg"},
						{"link":"/portfolio/p3-43448080831_c985f977d1_b.jpg"},
						{"link":"/portfolio/p3-28561617057_08af028eb5_b.jpg"},
						{"link":"/portfolio/p3-28561617307_1f2b02dcdf_b.jpg"},
						{"link":"/portfolio/p3-28561617437_668262c2a9_b.jpg"},
						{"link":"/portfolio/p3-28561617877_8acc39bb06_b.jpg"},
						{"link":"/portfolio/p3-28561618467_607f9529cc_b.jpg"},
						{"link":"/portfolio/p3-28561618747_93ee272a97_b.jpg"},
						{"link":"/portfolio/p3-28561619127_2750079131_b.jpg"},
						{"link":"/portfolio/p3-28561619337_a1ebfdcb63_b.jpg"},
						{"link":"/portfolio/p3-28561619687_474cf2f590_b.jpg"},
						{"link":"/portfolio/p3-28561620067_e59ca35aef_b.jpg"},
						{"link":"/portfolio/p3-28561620497_026f6ccc03_b.jpg"},
						{"link":"/portfolio/p3-28561621477_b13e1424f4_b.jpg"},
						{"link":"/portfolio/p3-41640617710_9eff63e72f_b.jpg"},
						{"link":"/portfolio/p3-42543644375_f414e75844_b.jpg"},
						{"link":"/portfolio/p3-42543645865_4a765d734b_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Renovating your garden can provide a fantastic return on investment, as a well-maintained and attractively designed yard can increase the value of your property and provide value long-term. Plus, having an outdoor space where you’re able to relax and unwind can have a positive effect on your health. Spending time in nature and simply viewing a garden provides a range of physical and psychological benefits. Nature and gardening help to reduce stress, improve mood, and enhance your overall wellbeing.</p>
				<p className="servicesExplainer">No matter how your garden currently looks, it can be converted into a space that really suits your style and matches your vision of the perfect garden.</p>
				<p className="servicesExplainer">if you have a garden that is underutilised or under loved, we offer a complete garden renovation service that will completely transform your outdoor space.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Spacious Office"
					img={[
						{"link":"/portfolio/p4-41536429264_7b01fce8fa_b.jpg"},
						{"link":"/portfolio/p4-41356473885_f3cd0a9212_b.jpg"},
						{"link":"/portfolio/p4-40450961660_052090f217_b.jpg"},
						{"link":"/portfolio/p4-40450961870_9d2bab6bf2_b.jpg"},
						{"link":"/portfolio/p4-41536429804_9fe176e6b8_b.jpg"},
						{"link":"/portfolio/p4-40450962020_b7a8f1e8c3_b.jpg"},
						{"link":"/portfolio/p4-41536431034_430180bacc_b.jpg"},
						{"link":"/portfolio/p4-40450962880_7d38f0bb4d_b.jpg"},
						{"link":"/portfolio/p4-41536428824_864f94c7af_b.jpg"},
						{"link":"/portfolio/p4-41536432904_d04a489094_b.jpg"},
						{"link":"/portfolio/p4-40450963480_8f98a9a36b_b.jpg"},
					]}
				>

				<p className="servicesExplainer bold">Transformation of an underutilized space into a spacious office</p>
				<p className="servicesExplainer">Offering shared office space is a great idea for entrepreneurs and start-ups to minimise their operational cost, they don’t have to pay utility bills which is a big bonus of shared office space. It’s not always just about renting a desk; working in shared space means that as a start-up they have access to a range of people on the same wavelength.</p>
				<p className="servicesExplainer">Refurbish your unused or existing office space and make that space work harder and smarter. Property people Care Ltd is a service-oriented company. We provide high quality remodelling and refurbishment services.</p>
				<p className="servicesExplainer">If you have a project in mind that you would like to discuss please contact us.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Timber decking"
					img={[
						{"link":"/portfolio/p5-39731380500_ef17169f88_b.jpg"},
						{"link":"/portfolio/p5-41540359871_8c2d13772e_b.jpg"},
						{"link":"/portfolio/p5-39731377410_e9a9b9bd30_b.jpg"},
						{"link":"/portfolio/p5-39731379070_0b483f9a04_b.jpg"},
						{"link":"/portfolio/p5-41499319132_afc3ce626d_b.jpg"},
						{"link":"/portfolio/p5-41540355541_d0d70ef092_b.jpg"},
					]}
				>

				<p className="servicesExplainer bold">Extend your living Space and increase Property value with decking.</p>
				<p className="servicesExplainer">Timber decking is a relatively new ‘lifestyle’ concept for the UK – and has become a widespread alternative to traditional hard terraces and patios.</p>
				<p className="servicesExplainer">A good design and well-planned decking in the garden, will create an extra room added on to your house where you can put furniture to relax on. It creates additional area for the kids to play. You can also turn this into a multi-purpose living space which increased the value of your property and make your property more desirable.</p>
				<p className="servicesExplainer">If your garden is uneven or you have a specific area that slopes hazardously, decking is an easy way to sort out the problem. You don’t need any costly landscaping and you get the added benefits of everything else decking has to offer.</p>
				<p className="servicesExplainer">It is easy to maintain; just give it a regular brush and clean with a quality decking cleaner. </p>
				<p className="servicesExplainer">If you’re interested in a decked area to enhance your garden and family or social life contact us today!</p>
				</PortfolioItem>

				<PortfolioItem
					title="Silicon render"
					img={[
						{"link":"/portfolio/p6-40175221025_8a32edd8f1_b.jpg"},
						{"link":"/portfolio/p6-27197174818_f2b226fdba_b.jpg"},
						{"link":"/portfolio/p6-41026369302_f9d987e533_b.jpg"},
						{"link":"/portfolio/p6-41026377852_9fd2ebedce_b.jpg"},
						{"link":"/portfolio/p6-41026382932_1a664a0336_b.jpg"},
						{"link":"/portfolio/p6-41026366862_5b5a867c3d_b.jpg"},
						{"link":"/portfolio/p6-41026389622_d80d88479c_b.jpg"},
						{"link":"/portfolio/p6-41026386082_d39d8c7413_b.jpg"},
						{"link":"/portfolio/p6-40175226575_54cf27aeb8_b.jpg"},
						{"link":"/portfolio/p6-40175224755_65ab8613c7_b.jpg"},
						{"link":"/portfolio/p6-26197770377_53b506bb90_b.jpg"},
						{"link":"/portfolio/p6-40359927754_099b33d2ef_b.jpg"},
						{"link":"/portfolio/p6-40175322715_af1fde479f_b.jpg"},
						{"link":"/portfolio/p6-41026431732_8cb92561dc_b.jpg"},
						{"link":"/portfolio/p6-41026370232_0036491b28_b.jpg"},
					]}
				>

				<p className="servicesExplainer bold">Exceptional benefits of Silicon render</p>
				<p className="servicesExplainer">Rendering your building exterior and exposed walls is great for protection and is a barrier from the outside world and ensures your brickwork remains unaffected.</p>
				<p className="servicesExplainer">With the passage of time exposed brickwork will get damaged and is under a constant state of attack from the elements. Solid brick walls are liable to issues from penetrating damp, where water seeps through the brickwork. Putting a render finish on the wall will help stop this and ensure that penetrating damp is not an issue through the brick.</p>
				<p className="servicesExplainer">With rendering you can freshen up even the roughest looking and tired exterior walls and make them look modern.</p>
				<ul>
					<li>Silicone render is a top of the range system that has several fantastic advantages.</li>
					<li>It is the most flexible system on the market.</li>
					<li>It is the easiest system to apply.</li>
					<li>It is hydrophobic (which makes it a self-cleaning render).</li>
					<li>It is anti-cracking, highly durable and breathable as well.</li>
					<li>It provides an excellent weather and ageing resistance, resistance to the low and increased temperature.</li>
					<li>It is available in different shades and colours.</li>
				</ul>
				</PortfolioItem>

				<PortfolioItem
					title="Flat roofing"
					img={[
						{"link":"/portfolio/p7-38960952830_28cdef1734_b.jpg"},
						{"link":"/portfolio/p7-39876073915_e6a79a2d97_b.jpg"},
						{"link":"/portfolio/p7-39876090185_85a82091e2_b.jpg"},
						{"link":"/portfolio/p7-26900843518_99ff99a423_b.jpg"},
						{"link":"/portfolio/p7-26900838738_d3f03d8dbf_b.jpg"},
						{"link":"/portfolio/p7-39876078975_e8e56bdcb4_b.jpg"},
						{"link":"/portfolio/p7-39876084195_c1c111b8ac_b.jpg"},
						{"link":"/portfolio/p7-38960952990_db5f97bdf4_b.jpg"},
						{"link":"/portfolio/p7-26900872298_53c63091da_b.jpg"},
					]}
				>

				<p className="servicesExplainer bold">Cost effective flat roofing with felt laying</p>
				<p className="servicesExplainer">Laying the felt on the flat roof is cost effective and a wise decision because covering the roof with roofing felt, creating a waterproof barrier between you and any rain that could potentially come.</p>
				<p className="servicesExplainer">A great benefit to roofing felt is that it can also act as a moisture barrier and moisture barrier in a roof is highly important nowadays since the way houses are now built with certain extras such as double glazing making air circulation more of a problem.</p>
				<p className="servicesExplainer">When the hot air is rising through the house and reaches the roof, any moisture in the air that condenses on the underside of the shingles, will get caught by the roofing felt.</p>
				<p className="servicesExplainer">It also creates a safer layer for them to work on, helping to make it less likely that they could slip. Another handy advantage is that the roofing felt does temporarily dry once the roof is up and complete.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Garden Patio"
					img={[
						{"link":"/portfolio/p8-38844061860_570f6e2dc5_b.jpg"},
						{"link":"/portfolio/p8-40612522132_0ae68fbdfb_b.jpg"},
						{"link":"/portfolio/p8-40654167441_68c0e893a9_b.jpg"},
						{"link":"/portfolio/p8-26783141118_a6f940342b_b.jpg"},
						{"link":"/portfolio/p8-40654172931_1d565c319c_b.jpg"},
						{"link":"/portfolio/p8-40654173861_86c58c1c7e_b.jpg"},
						{"link":"/portfolio/p8-40654177511_8d58d51ff7_b.jpg"},
						{"link":"/portfolio/p8-39758921305_3cee3d99b6_b.jpg"},
						{"link":"/portfolio/p8-39758767195_dd95dd67a8_b.jpg"},
						{"link":"/portfolio/p8-38844035820_6d9dc30666_b.jpg"},
						{"link":"/portfolio/p8-39944699854_613e98f4e5_b.jpg"},
						{"link":"/portfolio/p8-26783153858_a7c5312193_b.jpg"},
					]}
				>

				<p className="servicesExplainer bold">If you want a garden feature quite unlike any other</p>
				<p className="servicesExplainer">Natural Sandstone is the solution.</p>
				<p className="servicesExplainer">Every individual sandstone slab is unique, distinguished by innumerable tonal variations and enhanced by the differing riven profiles of each stone.</p>
				<p className="servicesExplainer">Sunset Buff natural stone paving comprises a warming mixture of buff and brown hues bringing colour and style to your garden.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Kitchen Face lift"
					img={[
						{"link":"/portfolio/p9-25662774347_855cbfed4c_b.jpg"},
						{"link":"/portfolio/p9-39638353975_637eaebb29_b.jpg"},
						{"link":"/portfolio/p9-40490632532_1590f57c37_b.jpg"},
						{"link":"/portfolio/p9-25662781507_e5505b1ec0_b.jpg"},
						{"link":"/portfolio/p9-25662781187_441d1e1b0c_b.jpg"},
						{"link":"/portfolio/p9-25662781887_331103fed1_b.jpg"},
						{"link":"/portfolio/p9-40490634232_eee354cfe0_b.jpg"},
						{"link":"/portfolio/p9-25662782937_1949e9e99a_b.jpg"},
						{"link":"/portfolio/p9-40490635922_a3eb5bfb55_b.jpg"},
						{"link":"/portfolio/p9-40490636952_627efe34c1_b.jpg"},
						{"link":"/portfolio/p9-25662785607_cbcbc47c62_b.jpg"},
						{"link":"/portfolio/p9-25662784107_471f80000f_b.jpg"},
						{"link":"/portfolio/p9-40490637402_1169905714_b.jpg"},
						{"link":"/portfolio/p9-40490637432_0d10978404_b.jpg"},
						{"link":"/portfolio/p9-25662785047_0eff3f5b03_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Kitchen face lift with the replacement of cabinet doors and drawer fronts only.</p>
				<p className="servicesExplainer">Complete transformation at the fraction of the cost of a re-fit</p>
				</PortfolioItem>

				<PortfolioItem
					title="New Bathroom Refit"
					img={[
						{"link":"/portfolio/p10-25012943751_5d0ef2e949_b.jpg"},
						{"link":"/portfolio/p10-25079898876_cdbbd5a6c4_b.jpg"},
						{"link":"/portfolio/p10-24475562384_dd68eb91e0_b.jpg"},
						{"link":"/portfolio/p10-25079898856_16348d644c_b.jpg"},
						{"link":"/portfolio/p10-25106234165_3956ffa114_b.jpg"},
						{"link":"/portfolio/p10-24988051602_ec67c75546_b.jpg"},
					]}
				>

				<p className="servicesExplainer">A complete refit of a bathroom and toilet with a new bath tub as well as a new toilet, wash basin new double glazed window, towel radiator, lighting and extractor fan.</p>
				</PortfolioItem>

				<PortfolioItem
					title="New Garden fence"
					img={[
						{"link":"/portfolio/p11-23148609826_3e943d816b_b.jpg"},
						{"link":"/portfolio/p11-22546202374_eed85a3705_b.jpg"},
						{"link":"/portfolio/p11-22878918140_c7fd64cb49_b.jpg"},
						{"link":"/portfolio/p11-22546200354_62eb813294_b.jpg"},
						{"link":"/portfolio/p11-22546200564_39d8396806_b.jpg"},
						{"link":"/portfolio/p11-22806749089_b271b8516f_b.jpg"},
						{"link":"/portfolio/p11-22878906360_6169f3c19f_b.jpg"},
					]}
				>

				<p className="servicesExplainer">A simple project to replace a week fence with timber post with a new modern stronger one with concrete posts.</p>
				<p className="servicesExplainer">The old fence was keeling over due to the weight of plants.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Shower room Refit after a major leak and water damage"
					img={[
						{"link":"/portfolio/p12-23174818495_78676bda14_b.jpg"},
						{"link":"/portfolio/p12-22547765363_5a7eaaff61_b.jpg"},
						{"link":"/portfolio/p12-22547774713_4b72f1208e_b.jpg"},
						{"link":"/portfolio/p12-22547776503_9dac7d20a4_b.jpg"},
						{"link":"/portfolio/p12-22546299394_0bd296a5d0_b.jpg"},
						{"link":"/portfolio/p12-22780661327_56154c1861_b.jpg"},
						{"link":"/portfolio/p12-22547772883_0f5909eef7_b.jpg"},
						{"link":"/portfolio/p12-22879011540_1284ec953f_b.jpg"},
						{"link":"/portfolio/p12-22879011930_160d67ec30_b.jpg"},
						{"link":"/portfolio/p12-22780659557_051a66fa17_b.jpg"},
						{"link":"/portfolio/p12-22806855489_0ae80ac65b_b.jpg"},
						{"link":"/portfolio/p12-23174823945_0e198e90fc_b.jpg"},
						{"link":"/portfolio/p12-23148712756_bdae9cbe66_b.jpg"},
						{"link":"/portfolio/p12-22546298064_f15444a6c4_b.jpg"},
						{"link":"/portfolio/p12-22547772263_b39ac1b502_b.jpg"},
						{"link":"/portfolio/p12-23174821285_61ea6ea1a1_b.jpg"},
						{"link":"/portfolio/p12-23148711826_afd7bb66eb_b.jpg"},
						{"link":"/portfolio/p12-22879008980_eaac4f8ff8_b.jpg"},
					]}
				>

				<p className="servicesExplainer">We were called in to investigate a major leak in 1st floor shower room in a 4 bedroom house. The leak turned out to be from a badly fitted shower with tiling and grout letting in water.</p>
				<p className="servicesExplainer">The damage had occurred over many months. Water penetrated into the next bedroom damaging the wall as well as into the kitchen downstairs. We removed all tiles, fitted aqua panel waterproof plasterboard on wall and applied a layer of water proof membrane. Water proof adhesive and the tiling. We re-plastered the wall in bedroom and ceiling in kitchen and redecorated the place. all done on a weekend with minimum disruption to the tenants.</p>
				</PortfolioItem>

				<PortfolioItem
					title="A refit with efficient use of a small bathroom"
					img={[
						{"link":"/portfolio/p13-24573891302_fc45259844_b.jpg"},
						{"link":"/portfolio/p13-24598149921_4673722013_b.jpg"},
						{"link":"/portfolio/p13-24076798464_c0ff742c0d_b.jpg"},
					]}
				>

				<p className="servicesExplainer">A complete refit of a shower room and toilet to incorporate instead a bath tub as well as a new toilet, wash basin, under floor heating, electric towel radiator, new lighting and extractor fan.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Abandoned Property"
					img={[
						{"link":"/portfolio/p14-19165865502_bc77cdc6ef_b.jpg"},
						{"link":"/portfolio/p14-18550969693_20f7043d97_b.jpg"},
						{"link":"/portfolio/p14-18983991788_1d97d2cce7_b.jpg"},
						{"link":"/portfolio/p14-18549033604_8e89cb1be6_b.jpg"},
						{"link":"/portfolio/p14-18985418149_8029d72919_b.jpg"},
						{"link":"/portfolio/p14-19145407886_e68e1562f4_b.jpg"},
						{"link":"/portfolio/p14-18549034034_ab215abb46_b.jpg"},
						{"link":"/portfolio/p14-18983900080_7088e268cc_b.jpg"},
						{"link":"/portfolio/p14-19175138211_b60dc362a7_b.jpg"},
						{"link":"/portfolio/p14-19171574425_da79dab4d2_b.jpg"},
						{"link":"/portfolio/p14-18984008198_ef8656cb52_b.jpg"},
						{"link":"/portfolio/p14-19175200611_9511e7d809_b.jpg"},
						{"link":"/portfolio/p14-18983978410_19bfd620a4_b.jpg"},
						{"link":"/portfolio/p14-18985496469_122bd9bf95_b.jpg"},
						{"link":"/portfolio/p14-18984070168_20a06149a8_b.jpg"},
						{"link":"/portfolio/p14-19175201891_9509958668_b.jpg"},
						{"link":"/portfolio/p14-18551050193_6ce61985b4_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Property People were contacted by a distraught landlady who had just returned to the country to find her rental property abandoned.</p>
				<p className="servicesExplainer">Never mind the unpaid rent payments, the damage to the property had been extensive.</p>
				<p className="servicesExplainer">Property People went in to assess the damage which included.</p>
				<ul>
					<li>Damp and water damage in 2 bedrooms.</li>
					<li>Bathroom ceiling fallen down.</li>
					<li>Wallpaper peeling off & damaged walls.</li>
					<li>Broken windows.</li>
					<li>Kitchen cabinets damaged, doors & drawers coming off.</li>
					<li>Boiler non-functional</li>
					<li>Flooring in bathroom and kitchen with missing & damaged tiles.</li>
				</ul>
				<p className="servicesExplainer">Property People were able to bring the property back to life in just under 2 weeks and on budget. The property was tenanted with professional tenants within 3 weeks of Property People having been called to action. You can see the transformation from the photos.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Yellow Printshop"
					img={[
						{"link":"/portfolio/p16-18668152608_9f464551c0_b.jpg"},
						{"link":"/portfolio/p16-17873906884_a4e9ca6014_b.jpg"},
						{"link":"/portfolio/p16-18470175956_a3b97939b7_b.jpg"},
						{"link":"/portfolio/p16-18308797868_0a650f7fb5_b.jpg"},
						{"link":"/portfolio/p16-18308798338_d85953579e_b.jpg"},
						{"link":"/portfolio/p16-17873909264_3cfd2cecec_b.jpg"},
						{"link":"/portfolio/p16-18496610645_ae8ce93f65_b.jpg"},
						{"link":"/portfolio/p16-18308800158_3c69a9cafa_b.jpg"},
						{"link":"/portfolio/p16-18308886040_f199ecf557_b.jpg"},
						{"link":"/portfolio/p16-17873910534_fde8c02dbe_b.jpg"},
						{"link":"/portfolio/p16-18310409249_0f7293ed3c_b.jpg"},
						{"link":"/portfolio/p16-18492242092_afe0d11912_b.jpg"},
						{"link":"/portfolio/p16-18308803028_4c8515eb07_b.jpg"},
						{"link":"/portfolio/p16-18492243422_d7f1fe99fd_b.jpg"},
						{"link":"/portfolio/p16-18498446581_9fa87f237d_b.jpg"},
						{"link":"/portfolio/p16-17873912684_43cf6380da_b.jpg"},
						{"link":"/portfolio/p16-18310411399_e4fe584d60_b.jpg"},
						{"link":"/portfolio/p16-18308803718_3b9ab62097_b.jpg"},
						{"link":"/portfolio/p16-18498446991_ff136e2d16_b.jpg"},
						{"link":"/portfolio/p16-18492244642_22cd84e290_b.jpg"},
						{"link":"/portfolio/p16-18308804788_6fd5ab694c_b.jpg"},
						{"link":"/portfolio/p16-18496614985_e098ea94a8_b.jpg"},
						{"link":"/portfolio/p16-18470182946_6818ee5222_b.jpg"},
						{"link":"/portfolio/p16-18308804958_73b993459e_b.jpg"},
						{"link":"/portfolio/p16-17873914424_e3ba36e32c_b.jpg"},
						{"link":"/portfolio/p16-17875922403_e9aa2e98a6_b.jpg"},
						{"link":"/portfolio/p16-18492245852_6015e0f8cd_b.jpg"},
						{"link":"/portfolio/p16-18308805618_7d9e612647_b.jpg"},
						{"link":"/portfolio/p16-18829648716_467d356d5e_b.jpg"},
					]}
				>

				<p className="servicesExplainer">We were given a brief by <a href="http://yellowprint.co.uk">Yellow Printshop</a> to get their newly acquired premises in East Sheen, refurbished and ready to be opened as a 3rd high street branch.</p>
				<p className="servicesExplainer">This retail space used to be a clothing shop with ceiling high, custom built wardrobes, shelves & big mirrors. There were changing rooms at the back and an area with mismatched plasterboard ceiling. We had to rip out all wardrobes cabinets & mirrors. Knock down the changing rooms. Rewire so that there were sufficient electrical plugs all over the shop. Re plaster all the walls to make it flush all the way to the back. Make new plasterboard ceiling with lighting to make it flush. Repair flooring and match with the existing one where it was missing or damaged.  And then at the paint and decorate with company colours with a beautiful finish.</p>
				<p className="servicesExplainer">As you can see the results speak for themselves. Feel free to contact <a href="http://yellowprint.co.uk">Yellow Printshop</a> for a reference.</p>
				</PortfolioItem>

				<PortfolioItem
					title="Gravel driveways are becoming extremely popular in the UK"
					img={[
						{"link":"/portfolio/p17-39659206275_e987b1bcaf_b.jpg"},
						{"link":"/portfolio/p17-26683374878_161f8885d6_b.jpg"},
						{"link":"/portfolio/p17-39843924064_7ac8f9daca_b.jpg"},
						{"link":"/portfolio/p17-39659213415_5f47304435_b.jpg"},
						{"link":"/portfolio/p17-39843925594_aba039e91d_b.jpg"},
						{"link":"/portfolio/p17-39659216575_8b8bb57858_b.jpg"},
						{"link":"/portfolio/p17-40511871512_287dce263a_b.jpg"},
						{"link":"/portfolio/p17-38744219420_d23269e6ce_b.jpg"},
						{"link":"/portfolio/p17-39843904784_4265a36c22_b.jpg"},
						{"link":"/portfolio/p17-39843905714_6a88cc4485_b.jpg"},
						{"link":"/portfolio/p17-39843907314_4610bfa178_b.jpg"},
						{"link":"/portfolio/p17-39843908584_921d29f3c0_b.jpg"},
						{"link":"/portfolio/p17-40511875772_26ed4fecf0_b.jpg"},
						{"link":"/portfolio/p17-25683840857_96432e19d3_b.jpg"},
						{"link":"/portfolio/p17-39843911464_be05f4fb15_b.jpg"},
						{"link":"/portfolio/p17-38744228110_da399ca8b4_b.jpg"},
					]}
				>

				<p className="servicesExplainer">Gravel driveways are great alternative to the slabs and tuff paver blocks.</p>
				<p className="servicesExplainer">They are generally less expensive than concrete or asphalt and became extremely popular in the UK and are a great alternative to slabs or block pavers. Gravel driveways allow you to get creative and can create a wonderful feature at the front of your home.</p>
				<p className="servicesExplainer">Gravel driveways are ideal as they significantly reduce the risk of flooding unlike standard block paved or slab driveways. The water is able to filter through your gravel and into the ground therefore creating a clear surface that is safe to use all year round.</p>
				</PortfolioItem>

			</Container>					
      </div>
    );
  }
}

export default PortfolioPage;