import React, { Component } from 'react'
import {
    Link,
    withRouter
  } from 'react-router-dom'
import './Services.css'
import Container from 'react-bootstrap/Container'
import Helmet from 'react-helmet'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PageHeader from '../../components/PageHeader'
import CallToAction from '../../components/CallToAction'

class ServicesPage extends Component {

  render() {

    const Services = [
      {
        "title":"REFURBISHMENT", 
        "text":"Low quality properties can create many problems for occupants including high utility bills and a wide variety of negative health impacts resulting from cold, damp and poorly ventilated homes. By refurbishing existing properties to a good standard of sustainable design, running costs will be lower, the internal environment healthier and as a result it will be a more desirable place to live/work for the occupants, potentially increasing value.", 
        "link":"/refurbishment",
        "img":"/home/banner-sustainable.jpg",
      },
      {
        "title":"EXTENSIONS", 
        "text":"Property People can see your dream extension/conversion project through to completion with a beautiful finish. We can take on all size of refurbishment projects. Call us to discuss your needs. We would be more than happy to provide a quote.", 
        "link":"/extensions",
        "img":"/home/banner-extensions.jpg",
      },
      {
        "title":"PAINTING", 
        "text":"Professional Painter and Decorators that have an eye for colour. They will bring with them years of experience and advice along with quality service. You will never be overcharged and we will provide a guarantee for the work carried out.", 
        "link":"/painting",
        "img":"/home/banner-painting.jpg",
      },
      {
        "title":"PLUMBING", 
        "text":"Gas safe registered, fully insured, qualified local plumbers that arrive on time and fix the problem at reasonable rates. You will never be overcharged and we will provide a guarantee for the work carried out.", 
        "link":"/plumbing",
        "img":"/home/banner-plumbing.jpg",
      },
      {
        "title":"ELECTRICS", 
        "text":"Fully insured Qualified Electricians that maintain high standards of safety while providing great quality of workmanship at reasonable rates. You will never be overcharged and we will provide a guarantee for the work carried out.", 
        "link":"/electrics",
        "img":"/home/banner-electrics.jpg",
      },
      {
        "title":"DAMP PROOFING", 
        "text":"Untreated damp can be hazardous to health and if the problem is not dealt correctly, it will return. Property People Damp expert will treat the root cause of the problem, re-plaster the area to make it look new, clean up after himself and provide a 3 year guarantee. If the problem returns so will we.", 
        "link":"/damp-proofing",
        "img":"/home/banner-damp.jpg",
      },
      {
        "title":"Handyman", 
        "text":"Fully insured Handyman that will follow your instructions. Need someone who is good with screws, nuts & bolts? Need help with putting together a cupboard or desk? call us!", 
        "link":"/handyman",
        "img":"/home/banner-handyman.jpg",
      },
    ]

    const ListServices = Services.map((item, index)=>
      <Row key={index} style={{marginTop:"1rem"}}>
        <Col lg={3}>
          <div style={{
            background:`url(${item.img})`,
            backgroundSize:"cover",
            height:"13rem",
            marginTop:"2rem",}}></div>
          {/* <img src="/home/banner-sustainable.jpg" style={{width:"100%"}}/> */}
        </Col>
        <Col lg={9}>
          <h2 className="sectionHead">{item.title}</h2>
          <p className="servicesExplainer bold">{item.text}</p>
          <Link to={item.link}>
              <button className="btn btn-secondary">
                Read more...
              </button>
            </Link>
        </Col>
      </Row>
    )

    return (
      <div className="servicesPageWrapper">

      <Helmet>
        <title>Property People Care - Services</title>
        <meta name="description" content="Property People Care" />
      </Helmet>

      <PageHeader 
        name="Services" 
        img="/home/banner-sustainable.jpg"
        color="var(--primary-blue)" 
      />

      <Container>
        {ListServices}
      </Container>

      <Container>
        <CallToAction />
      </Container>
      
      </div>
    )
  }
}

export default withRouter (ServicesPage)