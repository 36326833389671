import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopStoreNav from './components/TopStoreNav'
import TopHeader from './components/TopHeader'
import Footer from './components/Footer'

// Home Page
import Home from './pages/Home/Home'

// Service Pages
import ServicesPage from './pages/Services/Services'
import RefurbishmentPage from './pages/Services/Refurbishment'
import ExtensionsPage from './pages/Services/Extensions'
import PaintingPage from './pages/Services/Painting'
import PlumbingPage from './pages/Services/Plumbing'
import ElectricsPage from './pages/Services/Electrics'
import DampProofingPage from './pages/Services/DampProofing'
import HandymanPage from './pages/Services/Handyman'

// Blog Pages
import BlogPage from './pages/Blog/Blog'
import DiySpringRenovationPage from './pages/Blog/Articles/DiySpringRenovation'
import HowToReplaceToiletPage from './pages/Blog/Articles/HowToReplaceToilet'
import HowToFixTapPage from './pages/Blog/Articles/HowToFixTap'
import ScienceBehindDeepCleaningCarpetsPage from './pages/Blog/Articles/ScienceBehindDeepCleaningCarpets'
import HowToDiyTipsForBeginnersPage from './pages/Blog/Articles/HowToDiyTipsForBeginners'

// Other Pages
import TestimonialsPage from './pages/Testimonials/Testimonials'
import ContactPage from './pages/Contact/Contact'
import PortfolioPage from './pages/Portfolio/Portfolio'
import GuaranteePage from './pages/Guarantee/Guarantee'
import QuotePaintingPage from './pages/Quote/QuotePainting'
import NotFound from './pages/NotFound/NotFound'


export default class App extends Component {
  render() {
    return (
      <Router>
        <TopStoreNav/>
        <TopHeader/>

        <Switch>

          <Route path='/services'>
            <ServicesPage />
          </Route>

            <Route path='/refurbishment'>
              <RefurbishmentPage />
            </Route>

            <Route path='/extensions'>
              <ExtensionsPage />
            </Route>

            <Route path='/painting'>
              <PaintingPage />
            </Route>

            <Route path='/plumbing'>
              <PlumbingPage />
            </Route>

            <Route path='/electrics'>
              <ElectricsPage />
            </Route>

            <Route path='/damp-proofing'>
              <DampProofingPage />
            </Route>

            <Route path='/handyman'>
              <HandymanPage />
            </Route>

          <Route path='/blog'>
            <BlogPage />
          </Route>

              <Route path='/diy-spring-renovation-tips'>
                <DiySpringRenovationPage />
              </Route>

              <Route path='/how-to-replace-a-toilet-seat'>
                <HowToReplaceToiletPage />
              </Route>

              <Route path='/how-to-fix-a-tap'>
                <HowToFixTapPage />
              </Route>

              <Route path='/science-behind-deep-cleaning-carpets'>
                <ScienceBehindDeepCleaningCarpetsPage />
              </Route>

              <Route path='/how-to-diy-tips-for-beginners'>
                <HowToDiyTipsForBeginnersPage />
              </Route>

          <Route path='/portfolio'>
            <PortfolioPage />
          </Route>

          <Route path='/guarantee'>
            <GuaranteePage />
          </Route>
              
          <Route path='/testimonials'>
            <TestimonialsPage />
          </Route>

          <Route path='/contact'>
            <ContactPage />
          </Route>

          <Route path='/quote'>
            <QuotePaintingPage />
          </Route>

          <Route path='/'>
            <Home />
          </Route>

          <Route>
            <NotFound />
          </Route>

        </Switch>
        
        <Footer/>
      </Router>
    )
  }
}